import axios from "axios";

const HOLIDAYS_ENDPOINT = process.env.VUE_APP_ETCONSULTING_HOST + "/";

export default {
  getPendingApprovalHolidays(userId) {
    return axios.get(HOLIDAYS_ENDPOINT + "holidays/pendingApproval?userId=" + userId);
  },
  getUserHolidaysById(userId, beginDate, endDate) {
    return axios.get(HOLIDAYS_ENDPOINT + "holidays?userId=" + userId + "&beginDate=" + beginDate + "&endDate=" + endDate);
  },
  getHolidaySummaryById(userId, beginDate, endDate) {
    return axios.get(HOLIDAYS_ENDPOINT + "holidays/summary?userId=" + userId + "&beginDate=" + beginDate + "&endDate=" + endDate);
  },
  updateExtraHolidaysById(userId, extraDays, year) {
    let params = {
      userId: userId,
      extraDays: extraDays,
      year: year
    };
    return axios.put(HOLIDAYS_ENDPOINT + "holidays/extra", params);
  },
  insertExtraHolidaysById(userId, extraDays, year) {
    let params = {
      userId: userId,
      extraDays: extraDays,
      year: year
    };
    return axios.post(HOLIDAYS_ENDPOINT + "holidays/extra", params);
  },
  deleteExtraHolidaysById(userId, year) {
    return axios.delete(HOLIDAYS_ENDPOINT + "holidays/extra?userId=" + userId + "&year=" + year);
  },
  processPendingHolidays(userId, year) {
    return axios.post(HOLIDAYS_ENDPOINT + "holidays/processPending?userId=" + userId + "&year=" + year);
  },
  post(userId, date, holidayTypeId, year) {
    let params = {
      userId: userId,
      date: date,
      holidayTypeId: holidayTypeId,
      year: year
    };
    return axios.post(HOLIDAYS_ENDPOINT + "holidays", params);
  },
  edit(userId, date, holidayTypeId, year) {
    let params = {
      userId: userId,
      date: date,
      holidayTypeId: holidayTypeId,
      year: year
    };
    return axios.put(HOLIDAYS_ENDPOINT + "holidays", params);
  },
  delete(userId, date) {
    return axios.delete(HOLIDAYS_ENDPOINT + "holidays/" + userId + "/" + date);
  },
  approveHolidays(userId) {
    let params = {
      userId: userId
    };
    return axios.post(HOLIDAYS_ENDPOINT + "holidays/approve", params);
  },
  getNationalHolidays(year) {
    return axios.get(HOLIDAYS_ENDPOINT + "holidays/national?year=" + year);
  },
  checkIsNationalHoliday(date) {
    return axios.get(HOLIDAYS_ENDPOINT + "holidays/isNationalHoliday?date=" + date);
  },
  checkIsForbiddenHoliday2(date) {
    return axios.get(HOLIDAYS_ENDPOINT + "holidays/isForbiddenHoliday?date=" + date);
  }
};
