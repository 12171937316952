import Vue from "vue";
import Vuex from "vuex";

import { storeAuth } from "@/store/store_auth";
import { storeHolidays } from "@/store/store_holidays";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: storeAuth,
    holidays: storeHolidays
  }
});

export default store;
