<template>
  <article class="member">
    <img class="image" :src="imageUrl" :alt="name" />
    <h3 class="name">{{ name }}</h3>
    <p class="role">{{ role }}</p>
    <slot />
  </article>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.image {
  width: 100%;
  max-width: 500px;
  aspect-ratio: 4/3;
  object-fit: cover;
}
.name {
  font-size: 2rem;
  text-align: center;
}
.role {
  font-size: 1.6rem;
  text-align: center;
}
</style>
