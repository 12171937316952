import Vue from "vue";
import VueRouter from "vue-router";
import AppSidebar from "@/components/AppSidebar";
import PageLogin from "@/views/PageLogin.vue";
import PageWorkedHours from "@/views/PageWorkedHours.vue";
import PageError from "@/views/PageError.vue";
import tokens from "@/logic/tokens";
import PageLanding from "@/views/PageLanding.vue";
import PageDocumentation from "@/views/PageDocumentation.vue";
import PageCatering from "@/views/PageCatering.vue";
import PageHolidays from "@/views/PageHolidays.vue";
import PageUserInfo from "@/views/PageUserInfo.vue";
import PageHome from "@/views/PageHome.vue";
import PageAdminUsers from "@/views/admin/PageAdminUsers.vue";
import PageAdminCompanies from "@/views/admin/PageAdminCompanies.vue";
import PageAdminHolidays from "@/views/admin/PageAdminHolidays.vue";
import PageAdminCatering from "@/views/admin/PageAdminCatering.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: {
      name: "home"
    }
  },
  {
    path: "",
    name: "landing",
    component: PageLanding
  },
  {
    path: "/login/:redirect?",
    name: "login",
    component: PageLogin,
    props: true
  },
  {
    path: "",
    name: "logged",
    component: AppSidebar,
    redirect: {
      name: "home"
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: PageHome
      },
      {
        path: "/userinfo",
        name: "userinfo",
        component: PageUserInfo
      },
      {
        path: "/worked-hours",
        name: "worked-hours",
        component: PageWorkedHours
      },
      {
        path: "/catering",
        name: "catering",
        component: PageCatering
      },
      {
        path: "/holidays",
        name: "holidays",
        component: PageHolidays
      },
      {
        path: "/documentation",
        name: "documentation",
        component: PageDocumentation
      },
      {
        path: "/admin-users",
        name: "admin-users",
        component: PageAdminUsers
      },
      {
        path: "/admin-companies",
        name: "admin-companies",
        component: PageAdminCompanies
      },
      {
        path: "/admin-holidays",
        name: "admin-holidays",
        component: PageAdminHolidays
      },
      {
        path: "/admin-catering",
        name: "admin-catering",
        component: PageAdminCatering
      }
    ]
  },
  {
    path: "/error",
    name: "error",
    props: true,
    component: PageError
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const token = tokens.getToken();
  const publicPages = ["landing", "login"];
  if (!token && !publicPages.includes(to.name)) {
    next({ name: `login`, params: { path: to.name } });
  } else {
    next();
  }
});

export default router;
