import axios from "axios";

const USERS_ENDPOINT = process.env.VUE_APP_ETCONSULTING_HOST + "/";

export default {
  getAllUsers() {
    return axios.get(USERS_ENDPOINT + "users");
  },
  getUser(userId) {
    return axios.get(USERS_ENDPOINT + "users/" + userId);
  },
  post(user) {
    let params = {
      loginName: user.loginName,
      password: user.password,
      email: user.email,
      companyId: user.companyId,
      name: user.name,
      surnames: user.surnames,
      isAdminHolidays: user.isAdminHolidays,
      isSAHolidays: user.isSAHolidays,
      isAdminCatering: user.isAdminCatering,
      isSACatering: user.isSACatering,
      entryDate: user.entryDate
    };
    return axios.post(USERS_ENDPOINT + "users", params);
  },
  edit(user, password) {
    let params = {
      loginName: user.userName,
      name: user.name,
      surnames: user.surnames,
      password: password,
      email: user.email
    };
    if (user.outDate) {
      params.outDate = user.outDate;
    }
    return axios.put(USERS_ENDPOINT + "users/" + user.userId, params);
  },
  delete(userId) {
    return axios.delete(USERS_ENDPOINT + "users/" + userId);
  }
};
