import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VCalendar from "./calendar";
import Components from "ets-components";
import "ets-components/dist/ets-components.css";
import interceptor from "./logic/interceptors.js";

Vue.use(Components, {});

Vue.config.productionTip = false;
interceptor();

router.beforeEach((to, from, next) => {
  const defaultTitle = "ETS Serenitas";
  document.title = to.meta.title || defaultTitle;
  next();
});

new Vue({
  router,
  store,
  VCalendar,
  render: h => h(App)
}).$mount("#app");
