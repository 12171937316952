<template>
  <ets-structure>
    <div class="header-container">
      <div class="header">
        <div class="header-logo">
          <router-link to="/home">
            <img class="logo" src="isotipo.svg" alt="logo ETS Serenitas" />
          </router-link>
        </div>
        <button class="toggle" :class="{ 'toggle-active': showMobileMenu }" @click="toggleMobileMenu">
          <span />
          <span />
          <span />
        </button>
        <nav class="header-nav">
          <ul>
            <li v-if="this.workedHoursActivated"><router-link class="nav-item" to="/worked-hours">Control horario</router-link></li>
            <li v-if="this.cateringActivated"><router-link class="nav-item" to="/catering">Catering</router-link></li>
            <li v-if="this.holidaysActivated"><router-link class="nav-item" to="/holidays">Vacaciones</router-link></li>
            <li v-if="this.documentationActivated"><router-link class="nav-item" to="/documentation">Documentación</router-link></li>
            <li v-if="this.userInfoActivated"><router-link class="nav-item" to="/userinfo">Información personal</router-link></li>
            <li v-if="this.userInfoActivated && this.hasPermissionAdminHolidays">
              <router-link class="nav-item" to="/admin-users">Admin Usuarios</router-link>
            </li>
            <li v-if="this.cateringActivated && this.hasPermissionCatering">
              <router-link class="nav-item" to="/admin-catering">Admin Catering</router-link>
            </li>
            <li v-if="this.holidaysActivated && this.hasPermissionAdminHolidays">
              <router-link class="nav-item" to="/admin-holidays">Admin Vacaciones</router-link>
              <span v-if="this.holidaysActivated && this.hasPermissionAdminHolidays && badgeNumber !== 0" class="nav-item-badge">
                {{ badgeNumber }}
              </span>
            </li>
            <li class="header-nav-logout">
              <ets-button mode="success" text="Salir" @click="logOut" />
            </li>
          </ul>
        </nav>
        <div class="header-buttons">
          <ets-button mode="success" text="Salir" @click="logOut" />
        </div>
      </div>
    </div>
    <div class="page">
      <ets-page>
        <router-view />
      </ets-page>
    </div>
  </ets-structure>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import companies from "@/logic/companies";
import tokens from "@/logic/tokens";
import users from "@/logic/users";

export default {
  data: () => ({
    userInfoActivated: false,
    cateringActivated: false,
    holidaysActivated: false,
    workedHoursActivated: false,
    documentationActivated: false,
    hasPermissionAdminHolidays: false,
    hasPermissionCatering: false,
    showMobileMenu: false
  }),
  async mounted() {
    this.checkUser();
  },
  computed: {
    ...mapGetters(["getUserLogged", "getPendingApprovalHolidays"]),
    username() {
      if (this.getUserLogged) {
        return `${this.getUserLogged.loginName}`;
      }
      return null;
    },
    badgeNumber() {
      if (this.getUserLogged) {
        return this.getPendingApprovalHolidays;
      }
      return 0;
    }
  },
  methods: {
    ...mapActions(["fetchUserInfo", "logout"]),
    async getLoggedUser() {
      let token = tokens.getDecodedToken();
      if (token) {
        let response = await users.getUser(token.userId);
        return response.data;
      }
      return null;
    },
    logOut() {
      this.logout();
      this.$router.push({ name: "login" });
    },
    async isCateringActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.cateringActivated = company.data[0].catering;
    },
    async isHolidaysActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.holidaysActivated = company.data[0].holidays;
    },
    async isUserInfoActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.userInfoActivated = company.data[0].userInfo;
    },
    async isWorkedHoursActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.workedHoursActivated = company.data[0].workedHours;
    },
    async isDocumentationActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.documentationActivated = company.data[0].documentation;
    },
    async checkUser() {
      this.loggedUser = await this.getLoggedUser();
      this.hasPermissionAdminHolidays = this.loggedUser.isAdminHolidays || this.loggedUser.isSAHolidays;
      this.hasPermissionCatering = this.loggedUser.isAdminCatering || this.loggedUser.isSACatering;
    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    }
  },
  watch: {
    $route() {
      this.showMobileMenu = false;
    }
  },
  async created() {
    if (!this.getUserLogged) {
      await this.fetchUserInfo();
    }
    await this.isCateringActivated();
    await this.isHolidaysActivated();
    await this.isUserInfoActivated();
    await this.isWorkedHoursActivated();
    await this.isDocumentationActivated();
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 230px;
}
.header-container {
  width: 70%;
  margin: 0.5rem auto;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  position: fixed;
  left: 0;
  right: 10px;
  top: 0;
  background-color: rgba(255, 255, 255, 1);
}
.header-logo {
  display: flex;
  justify-content: flex-start;
}
.toggle {
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 40px;
  gap: 6px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  > span {
    display: block;
    height: 6px;
    width: 100%;
    background: #bce0e2;
    border-radius: 6px;
  }
  &:hover span {
    background: #31617a;
  }
  &.toggle-active + .header-nav {
    display: block;
  }
}
.header-nav {
  @include font-base;
  font-size: 1.6rem;
  flex-grow: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: #d3e4e3;
  padding: 2rem 0;
  display: none;
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    flex-direction: column;
  }
  li {
    list-style: none;
    padding: 0 3rem;
    position: relative;
  }
  a {
    color: $color-primary;
    &:hover {
      text-decoration: underline;
    }
  }
}
.header-buttons {
  display: none;
  justify-content: flex-end;
}
.nav-item-badge {
  background-color: #fa3e3e;
  border-radius: 100px;
  color: white;
  padding: 1px 3px;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
}
.nav-item.router-link-active,
.nav-item.router-link-exact-active {
  font-weight: bold;
}
.page {
  padding-top: 12rem;
  > *:first-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media only screen and (min-width: 1024px) {
  .toggle {
    display: none;
  }
  .header {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .header-nav {
    display: block;
    position: static;
    background: #fff;
    padding: 0;
    ul {
      gap: 0;
      flex-direction: row;
    }
    li {
      &::before {
        content: "·";
        position: absolute;
        left: 0;
      }
      &:first-child::before {
        content: "";
      }
    }
  }
  .header-buttons {
    display: flex;
  }
  .header-nav-logout {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header-container {
    width: 90%;
  }
}
</style>
