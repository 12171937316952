<template>
  <div class="weekly-header">
    <span v-for="(hour, i) in range" :key="i" class="hour p-s">{{ formatNumber(hour) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Number,
      default: 16
    },
    min: {
      type: Number,
      default: 7
    },
    max: {
      type: Number,
      default: 23
    }
  },
  computed: {
    range() {
      let min = this.min;
      let max = this.max;
      let steps = this.steps;
      let total = max - min;
      let totalSteps = Math.trunc(total / steps);
      let i = 0;
      let hours = Array.apply(null, { length: max + 1 - min }).map((_, idx) => {
        i++;
        if (i % totalSteps === 0) {
          return idx + min;
        }
      });
      return hours.filter(Boolean);
    }
  },
  methods: {
    formatNumber(number) {
      if (number < 10) {
        return `0${number}:00`;
      } else {
        return `${number}:00`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.weekly-header {
  width: 100%;
  background: #eeeeee;
  height: 42px;
  display: flex;
  align-items: center;
}
.hour {
  display: flex;
  height: 100%;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  color: $color-grey3;
  &:nth-child(2n) {
    display: none;
    @include laptop {
      display: flex;
    }
  }
  &:nth-child(3n) {
    display: none;
    @include tablet {
      display: flex;
    }
  }
}
</style>
