import Cookies from "js-cookie";
import axios from "axios";
import tokens from "@/logic/tokens";

const cookieUserToken = "tokenETConsulting";

// const domain = process.env.VUE_APP_WEB_INTRANET.includes("localhost") ? "" : process.env.VUE_APP_WEB_INTRANET.replace("//", "");
const domain = "";

export const storeAuth = {
  state: {
    userLogged: null
  },
  mutations: {
    setUserLogged(state, userLogged) {
      state.userLogged = userLogged;
    }
  },
  actions: {
    login(context, { username, password }) {
      const url = `${process.env.VUE_APP_ETCONSULTING_HOST}/login`;
      return new Promise((resolve, reject) => {
        const params = { loginName: username, password: password };
        axios
          .post(url, params)
          .then(response => {
            const accessToken = response.data.accessToken;
            Cookies.set(cookieUserToken, accessToken, { domain });
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchUserInfo(context) {
      const decodedToken = tokens.getDecodedToken();
      const url = `${process.env.VUE_APP_ETCONSULTING_HOST}/users/${decodedToken.userId}`;
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(response => {
            context.commit("setUserLogged", response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    logout(context) {
      Cookies.remove(cookieUserToken, { domain });
      context.commit("setUserLogged", null);
    }
  },
  getters: {
    getUserLogged: state => {
      return state.userLogged;
    }
  }
};
