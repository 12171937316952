<template>
  <div>
    <ets-page-error :error="error" />
  </div>
</template>

<script>
export default {
  name: "PageError",
  props: {
    error: Error
  }
};
</script>

<style scoped></style>
