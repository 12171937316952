<template>
  <div class="home">
    <div class="home-message">
      <h1>"Good people bring good things"</h1>
      <p>Hacer crecer a las personas es lo que nos hace cada día más grandes</p>
    </div>
    <div class="module-group">
      <router-link v-if="this.workedHoursActivated" to="/worked-hours" class="module-box worked-hours">
        <h2>Control horario</h2>
        <p>Hoy has trabajado {{ workedHoursToday }}</p>
      </router-link>
      <router-link v-if="this.cateringActivated" to="/catering" class="module-box catering">
        <h2>Catering</h2>
        <ets-alert v-if="!isTomorrowCateringSignedUp" type="warning">No estás apuntado mañana</ets-alert>
        <p v-else>Estás apuntado para mañana</p>
      </router-link>
      <router-link v-if="this.holidaysActivated" to="/holidays" class="module-box holidays">
        <h2>Vacaciones</h2>
        <p>Te quedan por solicitar {{ daysLeft }} días</p>
      </router-link>
      <!-- <router-link v-if="this.documentationActivated" to="/documentation" class="module-box documentation">
        <h2>Documentation</h2>
      </router-link>
      <router-link v-if="this.userInfoActivated" to="/userinfo" class="module-box user-info">
        <h2>User Info</h2>
      </router-link>
      <router-link v-if="this.userInfoActivated && this.hasPermissionAdminHolidays" to="/admin-users" class="module-box admin">
        <h2>Admin Users</h2>
      </router-link>
      <router-link v-if="this.cateringActivated && this.hasPermissionAdminCatering" to="/admin-catering" class="module-box admin">
        <h2>Admin Catering</h2>
      </router-link>
      <router-link v-if="this.holidaysActivated && this.hasPermissionAdminHolidays" to="/admin-holidays" class="module-box admin">
        <h2>Admin Holidays</h2>
      </router-link> -->
    </div>
    <div class="companies">
      <a href="https://www.etsfactory.com/" target="_blank">
        <img class="company-logo" src="@/assets/images/logo_etsfactory.png" alt="etsfactory" />
      </a>
      <a href="https://www.alveusinvesting.com/" target="_blank">
        <img class="company-logo" src="@/assets/images/logo_alveus.png" alt="alveus" />
      </a>
      <a href="https://domusfide.com/" target="_blank">
        <img class="company-logo" src="@/assets/images/logo_domusfide.png" alt="domus fide" />
      </a>
      <a href="https://www.savinco.org/" target="_blank">
        <img class="company-logo" src="@/assets/images/logo_savinco.png" alt="savinco" />
      </a>
      <a href="https://www.gurban.es/" target="_blank">
        <img class="company-logo" src="@/assets/images/logo_gurban.png" alt="gurban" />
      </a>
      <a href="https://www.bevirtus.com/" target="_blank">
        <img class="company-logo" src="@/assets/images/logo_virtus.png" alt="virtus" />
      </a>
      <a href="https://www.backbase.com/" target="_blank">
        <img class="company-logo" src="@/assets/images/logo_backbase.png" alt="backbase" />
      </a>
      <a href="https://www.forwardyou.com/es/" target="_blank">
        <img class="company-logo" src="@/assets/images/logo_fwu.png" alt="fwu" />
      </a>
      <a href="https://wealthabout.io/" target="_blank">
        <img class="company-logo" src="@/assets/images/logo_wealthabout.png" alt="wealthabout" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import catering from "@/logic/catering";
import companies from "@/logic/companies";
import checks from "@/logic/checks";
import dayjs from "dayjs";
import { EtsDateParser } from "ets-components/src/utils";
import holidays from "@/logic/holidays";

export default {
  data: () => ({
    daysLeft: 0,
    workedHoursToday: null,
    userInfoActivated: false,
    cateringActivated: false,
    holidaysActivated: false,
    workedHoursActivated: false,
    documentationActivated: false,
    hasPermissionAdminHolidays: false,
    hasPermissionCatering: false,
    hasPermissionAdminCatering: false,
    isTomorrowCateringSignedUp: false,
    currentDay: new Date()
  }),
  computed: {
    ...mapGetters(["getUserLogged"]),
    username() {
      if (this.getUserLogged) {
        return `${this.getUserLogged.loginName}`;
      }
      return null;
    }
  },
  methods: {
    ...mapActions(["fetchUserInfo", "logout"]),
    async isCateringActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.cateringActivated = company.data[0].catering;
    },
    async isHolidaysActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.holidaysActivated = company.data[0].holidays;
    },
    async isUserInfoActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.userInfoActivated = company.data[0].userInfo;
    },
    async isWorkedHoursActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.workedHoursActivated = company.data[0].workedHours;
    },
    async isDocumentationActivated() {
      const company = await companies.getCompany(this.getUserLogged.companyId);
      this.documentationActivated = company.data[0].documentation;
    },
    async getWorkedHours() {
      if (this.getUserLogged) {
        let response = await checks.getWorkedHours(EtsDateParser(this.currentDay, "YYYY-MM-DD"));
        const hours = response.data.day;
        const fullHours = Math.floor(hours);
        const hourDecimalPart = hours - fullHours;
        const minutes = Math.round(hourDecimalPart * 60);
        return fullHours.toString() + " horas y " + minutes.toString() + " minutos";
      }
    },
    async getIsTomorrowCateringSignedUp() {
      const now = dayjs();
      let response = await catering.getMonthDays(now.year(), now.month() + 1);
      const monthDates = response.data;
      const nextCateringDate = monthDates.find(date => dayjs(date[0]).isAfter(now));
      const date = dayjs(nextCateringDate[0]).format("YYYYMMDD");
      response = await catering.getUserCatering(date, date);
      const dates = response.data;
      return dates.length > 0;
    },
    async getPendingHolidays() {
      const userHolidays = await holidays.getHolidaySummaryById(
        this.getUserLogged.userId,
        dayjs(this.beginDate).format("YYYYMMDD"),
        dayjs(this.endDate).format("YYYYMMDD")
      );
      return userHolidays.data.free;
    }
  },
  async created() {
    if (!this.getUserLogged) {
      await this.fetchUserInfo();
    }

    await this.isCateringActivated();
    await this.isHolidaysActivated();
    await this.isUserInfoActivated();
    await this.isWorkedHoursActivated();
    await this.isDocumentationActivated();
    this.hasPermissionAdminHolidays = this.getUserLogged.isAdminHolidays || this.getUserLogged.isSAHolidays;
    this.hasPermissionCatering = this.getUserLogged.isAdminCatering || this.getUserLogged.isSACatering;
    this.hasPermissionAdminCatering = this.getUserLogged.isAdminCatering || this.getUserLogged.isSACatering;
    this.workedHoursToday = await this.getWorkedHours();
    this.isTomorrowCateringSignedUp = await this.getIsTomorrowCateringSignedUp();
    this.daysLeft = await this.getPendingHolidays();
  }
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
  font-family: "Playfair Display";
  color: $color-primary;
}
.home-message h1 {
  font-size: 4rem;
  letter-spacing: 0.01cm;
}
.home-message p {
  font-size: 1.75rem;
  margin: 0;
}
.module-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  max-width: 1100px;
}
.module-box {
  width: 100%;
  max-width: 350px;
  height: 225px;
  border-radius: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $color-primary;
  background-color: transparentize($color-secondary-light, 0.6);
  border: 1px solid #70c0c2;
  transition: box-shadow 0.2s;
}
.module-box:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  text-decoration: none;
}
.module-box h2 {
  font-size: 2rem;
}
.module-box p {
  font-size: 1.5rem;
}
// .worked-hours {
//   background-color: rgba(180, 58, 237, 0.1);
//   border: 1px solid rgba(180, 58, 237, 0.2);
// }
// .catering {
//   background-color: rgba(96, 176, 166, 0.1);
//   border: 1px solid rgba(96, 176, 166, 0.2);
// }
// .holidays {
//   background-color: rgba(248, 72, 72, 0.1);
//   border: 1px solid rgba(248, 72, 72, 0.2);
// }
// .user-info {
//   background-color: rgba(88, 242, 152, 0.1);
//   border: 1px solid rgba(88, 242, 152, 0.2);
// }
// .documentation {
//   background-color: rgba(247, 157, 73, 0.1);
//   border: 1px solid rgba(247, 157, 73, 0.2);
// }
// .admin {
//   background-color: rgba(51, 81, 252, 0.1);
//   border: 1px solid rgba(51, 81, 252, 0.2);
// }
.companies {
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 0.5rem;
  place-items: center;
  margin: 6rem 0;
}
.company-logo {
  max-width: 150px;
  max-height: 75px;
  width: auto;
  height: auto;
  filter: grayscale(1);
  opacity: 0.75;
  transition: all 0.2s;
  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .companies {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
