<template>
  <div>
    <h4 class="header-m title">{{ type }}</h4>
    <p class="header-l content">{{ totalHours }}h {{ totalMinutes }}m</p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    time: {
      type: Number,
      default: 0
    },
    accumulatedMinutes: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    totalHours: 0,
    originalMinutes: 0,
    totalMinutes: 0
  }),
  methods: {
    setOriginalValues() {
      const hours = parseInt(Number(this.time));
      const minutes = Math.floor((Number(this.time) - hours) * 60);
      this.totalHours = hours;
      this.totalMinutes = minutes;
      this.originalMinutes = minutes;
    }
  },
  created() {
    this.setOriginalValues();
  },
  watch: {
    accumulatedMinutes() {
      this.totalMinutes++;
      if (this.totalMinutes >= 60) {
        this.totalMinutes = 0;
        this.totalHours++;
      }
    },
    time() {
      this.setOriginalValues();
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: normal;
  text-align: center;
  letter-spacing: 0.25px;
  margin: 0;
  &::first-letter {
    text-transform: uppercase;
  }
}
.content {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
</style>
