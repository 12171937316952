<template>
  <div>
    <div class="sub-header-container">
      <div class="sub-header">
        <ets-button :disabled="!hasPermissionAdmin" text="Descargar resumen diario" mode="main" @click="openDownloadCateringDaily" />
        <ets-button :disabled="!hasPermissionAdmin" text="Descargar resumen mensual" mode="main" @click="downloadCateringMonthly" />
        <ets-button :disabled="!hasPermissionAdmin" text="Cargar menú" mode="main" @click="openUpdateCatering" />
        <ets-button :disabled="!hasPermissionAdmin" text="Borrar catering" mode="main" @click="openDeleteCatering" />
      </div>
    </div>

    <!-- Popup descarga fichero diario -->
    <ets-popup
      ref="DownloadCateringDailyPopup"
      title="Descargar resumen diario"
      v-if="showDownloadCateringDailyPopup"
      @accept="downloadCateringDaily"
      @close="closeDownloadCateringDaily"
    >
      <div class="popup-container">
        <div class="popup-col">
          <label class="popup-label">Fecha</label>
          <v-date-picker
            v-model="newDailyDate"
            :model-config="calendar.modelConfig"
            mode="date"
            :locale="calendar.calendarLocale"
            :disabled-dates="calendar.disabledDates"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input class="popup-input-date" :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </div>
      </div>
    </ets-popup>

    <!-- Popup carga menú semanal -->
    <ets-popup
      ref="UploadCateringWeeklyPopup"
      title="Cargar menú"
      v-if="showUploadCateringWeeklyPopup"
      @accept="uploadCateringWeekly"
      @close="closeUploadCateringWeekly"
    >
      <div class="popup-container">
        <div class="centered-container">
          <label class="popup-label">Fecha</label>
          <ets-date-picker
            v-model="newDailyDateMenu"
            :model-config="calendar.modelConfig"
            mode="date"
            :locale="calendar.calendarLocale"
            :disabled-dates="calendar.disabledDates"
            @selected-date="handleDateClicked"
          />
        </div>
        <div class="row">
          <div class="column">
            <label class="popup-label">Primer plato normal</label>
            <ets-input v-model="firstCourseNormal" class="popup-input" />
          </div>
          <div class="column">
            <label class="popup-label">Primer plato especial</label>
            <ets-input v-model="firstCourseSpecial" class="popup-input" />
          </div>
          <div class="column">
            <label class="popup-label">Segundo plato normal</label>
            <ets-input v-model="secondCourseNormal" class="popup-input" />
          </div>
          <div class="column">
            <label class="popup-label">Segundo plato especial</label>
            <ets-input v-model="secondCourseSpecial" class="popup-input" />
          </div>
          <div class="column">
            <label class="popup-label">Postre normal</label>
            <ets-input v-model="dessertNormal" class="popup-input" />
          </div>
          <div class="column">
            <label class="popup-label">Postre especial</label>
            <ets-input v-model="dessertSpecial" class="popup-input" />
          </div>
        </div>
      </div>
    </ets-popup>

    <!-- Popup borrar catering -->
    <ets-popup
      ref="DeleteCateringPopup"
      title="Borrar catering"
      v-if="showDeleteCateringPopup"
      @accept="deleteCateringPopup"
      @close="closeDeleteCateringPopup"
    >
      <div class="popup-container">
        <div class="popup-col">
          <label class="popup-label">Fecha</label>
          <v-date-picker
            v-model="deleteDate"
            :model-config="calendar.modelConfig"
            mode="date"
            :locale="calendar.calendarLocale"
            :disabled-dates="calendar.disabledDates"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input class="popup-input-date" :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
          <label class="popup-label">Empresa</label>
          <DropDown :options="companyOptions()" @item-selected="handleItemSelected" class="form-input popup-input" />
        </div>
      </div>
    </ets-popup>

    <!-- selector mes -->
    <div class="calendar">
      <div class="ets-calendar-container">
        <div class="selector ets-calendar">
          <div class="buttons-center">
            <ets-button :text="previousMonth" @click="getPreviousMonth" icon="chevron-left" />
            <span :text="currentMonth" class="current-year-span">
              {{ formattedCurrentMonth }}
            </span>
            <ets-button :text="nextMonth" @click="getNextMonth" reversed icon="chevron-right" />
          </div>
        </div>
      </div>
    </div>

    <ets-table :columns="columns" :data="this.usersInformation" :fill="true">
      <template slot="row" slot-scope="item">
        <td>
          <a :href="urlCatering(item.row.userId)">{{ item.row.fullName }}</a>
        </td>
        <td class="left">{{ item.row.consumed }}</td>
        <td class="left">{{ item.row.total }}</td>
        <td class="left">{{ item.row.today }}</td>
        <td class="left">{{ item.row.today_type }}</td>
        <td class="left">{{ item.row.tomorrow }}</td>
        <td class="left">{{ item.row.tomorrow_type }}</td>
      </template>
    </ets-table>
  </div>
</template>

<script>
import companies from "@/logic/companies";
import catering from "@/logic/catering";
import dayjs from "dayjs";
import DropDown from "@/components/DropDown.vue";
import { mapGetters } from "vuex";
import tokens from "@/logic/tokens";
import users from "@/logic/users";

export default {
  components: {
    DropDown
  },
  data: () => ({
    usersInformation: [],
    dates: [],
    loggedUser: null,
    currentMonth: new Date(),
    firstDayOfMonth: null,
    lastDayOfMonth: null,
    hasPermissionAdmin: false,
    columnsUsers: [],
    days: null,
    columnsTotal: ["Total"],
    columnsTotalMenus: [],
    totalMenusDay: {},
    showDownloadCateringDailyPopup: false,
    newDailyDate: dayjs(new Date()).format("DD/MM/YYYY"),
    calendar: {
      calendarLocale: { firstDayOfWeek: 2, masks: { input: "DD/MM/YYYY", weekdays: "W" } },
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY"
      },
      disabledDates: { weekdays: [1, 7] },
      beginDate: null,
      endDate: null
    },
    showUploadCateringWeeklyPopup: false,
    newDailyDateMenu: dayjs(new Date()).format("DD/MM/YYYY"),
    firstCourseNormal: "",
    secondCourseNormal: "",
    dessertNormal: "",
    firstCourseSpecial: "",
    secondCourseSpecial: "",
    dessertSpecial: "",
    showDeleteCateringPopup: false,
    selectedCompanyId: null,
    deleteDate: dayjs(new Date()).format("DD/MM/YYYY")
  }),
  created() {
    this.fetchCompanies();
  },
  async mounted() {
    this.checkUser();
    await this.calculateFirstAndLastDay();
    await this.getData();
  },
  computed: {
    previousMonth() {
      return dayjs(dayjs(this.currentMonth).subtract(1, "month")).format("MMMM YYYY");
    },
    nextMonth() {
      return dayjs(dayjs(this.currentMonth).add(1, "month")).format("MMMM YYYY");
    },
    formattedCurrentMonth() {
      return dayjs(this.currentMonth).format("MMMM YYYY");
    },
    columns() {
      return [
        {
          text: "Apellidos, Nombre",
          key: "fullName",
          sorteable: false
        },
        {
          text: "Consumidos",
          key: "consumed",
          sorteable: false
        },
        {
          text: "Total",
          key: "total",
          sorteable: false
        },
        {
          text: "Hoy",
          key: "today",
          sorteable: false
        },
        {
          text: "Tipo",
          key: "today_type",
          sorteable: false
        },
        {
          text: "Mañana",
          key: "tomorrow",
          sorteable: false
        },
        {
          text: "Tipo",
          key: "tomorrow_type",
          sorteable: false
        }
      ];
    }
  },
  methods: {
    ...mapGetters(["getUserLogged"]),
    async getLoggedUser() {
      let token = tokens.getDecodedToken();
      if (token) {
        let response = await users.getUser(token.userId);
        return response.data;
      }
      return null;
    },
    getPreviousMonth() {
      this.currentMonth = dayjs(this.currentMonth).subtract(1, "month");
      this.calculateFirstAndLastDay();
    },
    getNextMonth() {
      this.currentMonth = dayjs(this.currentMonth).add(1, "month");
      this.calculateFirstAndLastDay();
    },
    calculateFirstAndLastDay() {
      this.firstDayOfMonth = dayjs(this.currentMonth).startOf("month").toDate();
      this.lastDayOfMonth = dayjs(this.currentMonth).endOf("month").toDate();
    },
    urlCatering(userId) {
      return `/catering?manageUserId=${userId}`;
    },
    normalize(name) {
      return name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },
    async getData() {
      // users
      await this.getLoggedUser().then(user => {
        this.loggedUser = user;
      });
      let response = await companies.getCompanyUsers(this.loggedUser.companyId, "catering");
      let usersInfo = response.data;

      const users_partial = [];
      await Promise.all(
        usersInfo.map(async user => {
          const userCatering = await catering.getCateringSummary(
            user.userId,
            dayjs(this.firstDayOfMonth).format("YYYYMMDD"),
            dayjs(this.lastDayOfMonth).format("YYYYMMDD")
          );
          const newUser = Object.assign(user, userCatering.data);
          newUser.fullName = newUser.surnames + ", " + newUser.name;
          users_partial.push(newUser);
        })
      );
      let sortedUsers = users_partial.sort((a, b) => (this.normalize(a["fullName"]) > this.normalize(b["fullName"]) ? 1 : -1));
      this.usersInformation = sortedUsers;
    },
    async checkUser() {
      this.loggedUser = await this.getLoggedUser();
      this.hasPermissionAdmin = this.loggedUser.isAdminCatering || this.loggedUser.isSACatering;
      if (!this.hasPermissionAdmin) {
        this.$router.push({ name: "userinfo" });
      }
    },
    openDownloadCateringDaily() {
      this.showDownloadCateringDailyPopup = true;
    },
    closeDownloadCateringDaily() {
      this.showDownloadCateringDailyPopup = false;
    },
    async downloadCateringDaily() {
      this.closeDownloadCateringDaily();
      try {
        const date = dayjs(this.newDailyDate, "DD/MM/YYYY").format("YYYYMMDD");
        const response = await catering.getDailySummary(date);
        const fileUrl = window.URL.createObjectURL(response.data);
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `catering_daily_${date}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      } catch (error) {
        console.error("Error downloading catering daily file:", error);
      }
    },
    async downloadCateringMonthly() {
      try {
        const date = dayjs(this.currentMonth).endOf("month").format("YYYYMMDD");
        const response = await catering.getMonthlySummary(date);
        const fileUrl = window.URL.createObjectURL(response.data);
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `catering_monthly_${date}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      } catch (error) {
        console.error("Error downloading catering monthly file:", error);
      }
    },
    async openUpdateCatering() {
      this.newDailyDateMenu = new Date();
      this.fillMenus();
      this.showUploadCateringWeeklyPopup = true;
    },
    async uploadCateringWeekly() {
      let newMenu = {
        date: dayjs(this.newDailyDateMenu).format("YYYYMMDD"),
        firstCourseNormal: this.firstCourseNormal,
        secondCourseNormal: this.secondCourseNormal,
        dessertNormal: this.dessertNormal,
        firstCourseSpecial: this.firstCourseSpecial,
        secondCourseSpecial: this.secondCourseSpecial,
        dessertSpecial: this.dessertSpecial
      };
      let response = await catering.getMenuList(dayjs(this.newDailyDateMenu).format("YYYYMMDD"));
      if (response.data.length !== 0) {
        await catering.editMenuList(newMenu);
      } else {
        await catering.postMenuList(newMenu);
      }
    },
    async closeUploadCateringWeekly() {
      this.showUploadCateringWeeklyPopup = false;
    },
    async openDeleteCatering() {
      this.selectedCompanyId = "";
      this.showDeleteCateringPopup = true;
    },
    async deleteCateringPopup() {
      let date = dayjs(this.deleteDate, "DD/MM/YYYY").format("YYYYMMDD");
      catering.deleteCatering(this.selectedCompanyId, date);
      this.showDeleteCateringPopup = false;
    },
    async closeDeleteCateringPopup() {
      this.showDeleteCateringPopup = false;
    },
    async fetchCompanies() {
      let allCompanies = await companies.getAllCompanies();
      const allCompaniesData = allCompanies.data.reduce((acc, curr) => {
        acc[curr.companyId] = curr.name;
        return acc;
      }, {});
      this.dropdownOptions = allCompaniesData;
    },
    companyOptions() {
      return this.dropdownOptions;
    },
    handleItemSelected(key) {
      this.selectedCompanyId = key;
    },
    async handleDateClicked(date) {
      this.newDailyDateMenu = date;
      this.fillMenus();
    },
    async fillMenus() {
      let response = await catering.getMenuList(dayjs(this.newDailyDateMenu).format("YYYYMMDD"));
      if (response.data.length !== 0) {
        this.firstCourseNormal = response.data[0]["firstCourseNormal"];
        this.secondCourseNormal = response.data[0]["secondCourseNormal"];
        this.dessertNormal = response.data[0]["dessertNormal"];
        this.firstCourseSpecial = response.data[0]["firstCourseSpecial"];
        this.secondCourseSpecial = response.data[0]["secondCourseSpecial"];
        this.dessertSpecial = response.data[0]["dessertSpecial"];
      } else {
        this.firstCourseNormal = "";
        this.secondCourseNormal = "";
        this.dessertNormal = "";
        this.firstCourseSpecial = "";
        this.secondCourseSpecial = "";
        this.dessertSpecial = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-header-container {
  margin-top: 2rem;
}
.sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
}
.users-container {
  width: 65%;
  margin: 5rem auto;
}
.users {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.users li {
  display: flex;
  justify-content: space-between;
}
.calendar ::v-deep {
  .calendar-wrapper {
    justify-content: space-around;
  }
}
.ets-calendar-container {
  width: 100%;
}
.current-year-span {
  color: #757575;
  margin: 0 10px;
}
.next-button {
  ::v-deep .button-content {
    display: flex;
    flex-direction: row-reverse;
  }
}
.selector {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  align-items: center;
}
.buttons-center {
  display: flex;
  align-items: center;
}
.popup-container {
  display: inline-flex;
}
.popup-col {
  display: flex;
  flex-direction: column;
  min-width: 280px;
}
.popup-label {
  margin-bottom: 0.5rem;
  display: block;
}
.popup-input-date {
  width: 100%;
  background: none;
  border: 1px solid #e0e0e0;
  padding: 1rem;
}
.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Agrega desplazamiento vertical */
  max-height: calc(100vh - 100px); /* Establece una altura máxima */
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.column {
  flex: 0 0 calc(50% - 10px); /* Ancho ajustado */
  margin: 5px; /* Margen ajustado */
  max-width: calc(50% - 10px); /* Ancho máximo ajustado */
}
.centered-container {
  margin-bottom: 20px;
}
</style>
