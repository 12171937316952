<template>
  <ets-structure>
    <div class="header-container">
      <div class="header">
        <div class="header-logo">
          <router-link to="/">
            <img class="logo" src="isotipo.svg" alt="Logo ETS Serenitas" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="page">
      <ets-page>
        <div class="form-wrapper">
          <form class="form" @submit.prevent="logInHandler">
            <div class="card-root">
              <div class="card-header">
                <h2 class="card-title">ETS Serenitas</h2>
              </div>
              <div class="card-content">
                <div class="input-group">
                  <label for="username">Usuario</label>
                  <input class="input" id="username" type="text" required v-model="username" />
                </div>
                <div class="input-group">
                  <label for="password">Contraseña</label>
                  <input class="input" id="password" type="password" required v-model="password" />
                </div>
              </div>
              <div class="card-footer">
                <ets-button class="button-full" mode="main" text="Entrar" />
              </div>
            </div>
          </form>
        </div>
      </ets-page>
    </div>
  </ets-structure>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    redirect: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    username: "",
    password: "",
    error: false
  }),
  methods: {
    ...mapActions(["login", "fetchPendingApprovalHolidays"]),
    async logInHandler() {
      this.error = false;
      const { username, password } = this;
      try {
        await this.login({ username, password });
        const path = this.$route.params.path;
        if (path) {
          this.$router.replace({ path: `/${path}` });
        } else if (this.redirect) {
          location.href = decodeURIComponent(this.redirect);
        } else {
          this.$router.push({ name: "home" });
        }
        this.fetchPendingApprovalHolidays();
        this.intervalId = setInterval(() => {
          this.fetchPendingApprovalHolidays();
        }, 10 * 1000);
      } catch {
        this.error = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 230px;
}
.header-container {
  width: 70%;
  margin: 0.5rem auto;
  box-sizing: border-box;
}
.header {
  display: flex;
  align-items: center;
  padding: 2rem 4rem;
  position: fixed;
  left: 0;
  right: 10px;
  top: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
}
.header-logo {
  display: flex;
  justify-content: flex-start;
}
// .page {
//   height: 100vh;
//   position: relative;
//   background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("../assets/images/landing.jpg");
//   background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
//   background-position: 100% 0;
// }
.form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}
.form {
  width: 100%;
  max-width: 42rem;
}
.card-root {
  border-radius: 1rem;
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
  color: $color-primary;
  padding: 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
.card-header {
  gap: 0.2rem;
  text-align: center;
}
.card-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
.card-title {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -0.015em;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.input {
  font-family: inherit;
  color: $color-secondary;
  width: 100%;
  border: none;
  border-bottom: 1px solid #dadada;
  padding: 1em 0.75em;
  margin: 0.75rem 0 1.5rem;
  &:focus {
    outline: none;
    border-bottom-color: $color-primary;
  }
  &::placeholder {
    opacity: 0.25;
  }
}
.button-full {
  width: 100%;
  padding: 1.25rem !important;
  margin: 0 !important;
  // border-radius: 4px !important;
}
</style>
