<template>
  <div class="page-worked-hours">
    <div class="checks">
      <div class="bars">
        <div class="header-title-container">
          <h3 class="header-l header-title">
            <span>Resumen semanal</span>
            <span>({{ monday | etsdate }} - {{ friday | etsdate }})</span>
          </h3>
        </div>
        <weekly-checks class="weekly-bars" :checks="checksBars" :today-date="todayDate" :current-date="currentDay" />
      </div>
      <div class="today">
        <div class="header-title-container">
          <ets-icon @click="prevDate" icon="chevron-left" class="chevron enabled" />
          <ets-icon @click="nextDate" icon="chevron-right" class="chevron" :class="isBeforeToday ? 'enabled' : 'disabled'" />
          <h3 class="header-l header-title">{{ currentDay | etsdate("dddd D MMM YYYY") }}</h3>
        </div>
        <section class="today-controls">
          <hours-summary
            v-if="!workedHoursLoading"
            :day-hours="summary.day"
            :week-hours="summary.week"
            :month-hours="summary.month"
            :acummulated-minutes="acummulatedMinutes"
          />
          <ets-spinner v-else />
          <form class="today-check" @submit.prevent>
            <ets-input-number :value="hour" :max-value="maxHour" inputWidth="60px" time @input="dirtyHours" />
            <span class="separator">:</span>
            <ets-input-number :value="minutes" :max-value="maxMinutes" inputWidth="60px" time @input="dirtyMinutes" />
            <ets-button
              v-if="lastCheckType === 'IN'"
              @click="checkOut"
              class="check-button"
              text="Check OUT"
              size="xl"
              mode="main"
              submit
              :disabled="isWeekend"
            />
            <ets-button v-else class="check-button" @click="checkIn" text="Check IN" size="xl" mode="main" submit :disabled="isWeekend" />
          </form>
          <ets-input
            v-model="comment"
            class="comment-input"
            :rows="3"
            placeholder="Escribe un comentario (opcional)"
            :max-length="120"
            textarea
          />
          <ets-alert v-if="error" type="error" class="error">
            <p class="error-msg">{{ error.message }}</p>
          </ets-alert>
          <ets-table v-if="checksList" :columns="columns" :data="checksList" :fill="true" class="checks-list condensed">
            <template slot="row" slot-scope="check">
              <template v-if="check.row">
                <td>CHECK {{ check.row.checkType }}</td>
                <td>{{ check.row.checkTime | etsdate("HH:mm") }}</td>
                <td class="comment">{{ check.row.comment }}</td>
                <td>
                  <ets-icon
                    v-if="check.row.last"
                    @click="deleteCheck(check.row.checkId)"
                    width="20px"
                    height="20px"
                    icon="trash"
                    class="trash"
                  />
                </td>
              </template>
            </template>
          </ets-table>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import WeeklyChecks from "@/components/WorkedHours/WeeklyChecks";
import HoursSummary from "@/components/WorkedHours/HoursSummary";
import checks from "@/logic/checks";
import { EtsDateParser } from "ets-components/src/utils";
import { mapGetters } from "vuex";

export default {
  components: {
    WeeklyChecks,
    HoursSummary
  },
  data: () => ({
    hour: 0,
    minutes: 0,
    comment: "",
    checks: [],
    currentDay: new Date(),
    todayDate: new Date(),
    checksBars: [],
    error: "",
    summary: null,
    acummulatedMinutes: -1,
    workedHoursLoading: true,
    checkLoading: false,
    deleteloading: false,
    polling: null,
    isDirty: false
  }),
  computed: {
    ...mapGetters(["getUserLogged"]),
    checksList() {
      if (this.checks.length !== 0) {
        this.checks.slice(-1)[0].last = true;
        return this.checks;
      }
      return null;
    },
    isBeforeToday() {
      let date = new Date(this.currentDay);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return date < today;
    },
    lastCheckType() {
      if (this.checks.length !== 0) {
        return this.checks.slice(-1)[0].checkType;
      } else {
        return null;
      }
    },
    maxHour() {
      if (this.isToday) {
        return this.todayDate.getHours();
      } else {
        return 23;
      }
    },
    maxMinutes() {
      if (this.isToday) {
        return this.hour < this.maxHour ? 59 : this.todayDate.getMinutes();
      } else {
        return 59;
      }
    },
    isToday() {
      const today = new Date();
      return (
        this.currentDay.getDate() == today.getDate() &&
        this.currentDay.getMonth() == today.getMonth() &&
        this.currentDay.getFullYear() == today.getFullYear()
      );
    },
    columns() {
      return [
        {
          text: "Estado",
          key: "check_type",
          sorteable: false
        },
        {
          text: "Hora",
          key: "check_time",
          sorteable: false
        },
        {
          text: "Comentario",
          key: "comment",
          sorteable: false
        },
        {
          text: " ",
          sorteable: false,
          class: "icons"
        }
      ];
    },
    calculatedDate() {
      return `${EtsDateParser(this.currentDay, "YYYY-MM-DD")} ${this.hour}:${this.minutes} `;
    },
    monday() {
      let date = new Date(this.currentDay);
      let day = date.getDay() || 7; // Get current day number, converting Sun. to 7
      if (day !== 1) {
        date.setHours(-24 * (day - 1)); // Set the hours to day number minus 1
      }
      return date;
    },
    friday() {
      let date = new Date(Number(this.monday));
      date.setDate(date.getDate() + 4);
      return date;
    },
    isWeekend() {
      const today = this.currentDay.getDay();
      return today === 0 || today === 6;
    },
    lastCheckIsOpen() {
      if (this.checks.length !== 0) {
        return this.checks[this.checks.length - 1].checkType === "IN";
      }
      return false;
    }
  },
  methods: {
    async checkIn() {
      this.error = null;
      if (!this.checkLoading) {
        try {
          this.checkLoading = true;
          let response = await checks.post("IN", this.calculatedDate, this.comment);
          this.checks = response.data;
          this.checkLoading = false;
          this.getChecksBars();
          this.getWorkedHours();
          this.getChecksList();
        } catch (error) {
          this.checkLoading = false;
          this.error = error.response.data;
        }
      }
      this.comment = "";
    },
    async checkOut() {
      this.error = null;
      try {
        let response = await checks.post("OUT", this.calculatedDate, this.comment);
        this.checks = response.data;
        this.getChecksBars();
        this.getWorkedHours();
        this.getChecksList();
      } catch (error) {
        this.error = error.response.data;
      }
      this.comment = "";
    },
    async deleteCheck(id) {
      this.error = null;
      if (!this.deleteloading) {
        try {
          this.deleteloading = true;
          let response = await checks.delete(id);
          this.checks = response.data;
          this.getChecksBars();
          this.getWorkedHours();
          this.getChecksList();
          this.deleteloading = false;
        } catch (error) {
          this.error = error.response.data;
          this.deleteloading = false;
        }
      }
    },
    async getChecksBars() {
      let response = await checks.getChecksRange(EtsDateParser(this.monday, "YYYY-MM-DD"), EtsDateParser(this.friday, "YYYY-MM-DD"));
      this.checksBars = response.data;
    },
    async getChecksList() {
      let response = await checks.getChecksList(EtsDateParser(this.currentDay, "YYYY-MM-DD"));
      this.checks = response.data;
    },
    async getWorkedHours() {
      if (this.getUserLogged) {
        let response = await checks.getWorkedHours(EtsDateParser(this.currentDay, "YYYY-MM-DD"));
        this.summary = response.data;
        this.workedHoursLoading = false;
      }
    },
    prevDate() {
      this.currentDay = new Date(this.currentDay.setDate(this.currentDay.getDate() - 1));
      if (this.currentDay.getDay() === 0) {
        this.currentDay = new Date(this.currentDay.setDate(this.currentDay.getDate() - 2));
        this.getChecksBars();
      }
    },
    nextDate() {
      if (this.isBeforeToday) {
        this.currentDay = new Date(this.currentDay.setDate(this.currentDay.getDate() + 1));
        if (this.currentDay.getDay() > 5) {
          this.currentDay = new Date(this.currentDay.setDate(this.currentDay.getDate() + 2));
          this.getChecksBars();
        }
      }
    },
    setCurrentTime() {
      this.todayDate = new Date();
      if (this.isToday && this.lastCheckIsOpen) {
        this.acummulatedMinutes++;
      }
      if (!this.isDirty) {
        this.minutes = this.todayDate.getMinutes();
        this.hour = this.todayDate.getHours();
        if (this.isToday) {
          this.currentDay = this.todayDate;
        }
      }
    },
    timer() {
      const interval = (60 - new Date().getSeconds()) * 1000 + 5;
      this.setCurrentTime();
      clearInterval(this.polling);
      this.polling = setInterval(this.timer, interval);
    },
    dirtyHours(value) {
      this.isDirty = true;
      this.hour = value;
    },
    dirtyMinutes(value) {
      this.isDirty = true;
      this.minutes = value;
    }
  },
  watch: {
    getUserLogged() {
      this.getWorkedHours();
    },
    hour() {
      if (this.hour === this.maxHour) {
        if (this.minutes > this.maxMinutes) {
          this.minutes = this.maxMinutes;
        }
      }
    },
    currentDay() {
      if (this.isToday && this.hour > this.maxHour) {
        this.hour = this.maxHour;
      }
      this.getChecksList();
      this.getWorkedHours();
    }
  },
  created() {
    this.timer();
    this.getChecksList();
    this.getChecksBars();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  }
};
</script>

<style lang="scss" scoped>
.page-worked-hours {
  margin: auto;
  padding-bottom: 8rem;
  max-width: 1500px;
}
.header-title-container {
  margin: 2.4rem 0 2rem;
  display: flex;
  align-items: center;
}
.header-title {
  margin: 0 1rem;
  color: #4f4f4f;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
}
.chevron {
  border-radius: 50%;
  &.enabled {
    cursor: pointer;
  }
}
.chevron.enabled:hover {
  background: $color-grey6;
  & ::v-deep path {
    fill: $color-main;
  }
}
.weekly-bars {
  display: none;
}
.chevron.disabled ::v-deep path {
  fill: $color-grey5;
}
.checks {
  display: grid;
  gap: 2rem;
}
.today {
  max-width: 400px;
  margin: auto;
}
.today-controls {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.today-check {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.separator {
  margin: 0 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.comment-input {
  margin: 0px auto;
  margin-top: 1.5rem;
}
.checks-list {
  margin-top: 2rem;
  & ::v-deep .icons {
    width: 30px;
  }
}
.comment {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.trash {
  &:hover ::v-deep path {
    fill: $color-main;
  }
  & ::v-deep path {
    fill: $color-red;
  }
}
.error {
  width: 100%;
}
.error-msg::first-letter {
  text-transform: capitalize;
}
.check-button {
  margin: 0 !important;
  margin-left: 1rem !important;
  width: 100px;
}

@media only screen and (min-width: 600px) {
  .weekly-bars {
    display: flex;
  }
}
@media only screen and (min-width: 1024px) {
  .checks {
    grid-template-columns: 7fr 2.5fr;
  }
}
</style>
