<template>
  <div>
    <select class="select" v-model="selectedItem" @change="handleSelectionChange">
      <option v-for="(value, key) in options" :key="key" :value="key">{{ value }}</option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: null
    };
  },
  methods: {
    handleSelectionChange() {
      this.$emit("item-selected", this.selectedItem);
    }
  },
  props: ["options"]
};
</script>

<style lang="scss" scoped>
.select {
  width: 100%;
  background: none;
  padding: 1rem;
  margin-top: 0.5rem;
}
</style>
