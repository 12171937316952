<template>
  <div class="summary">
    <h3 class="header-l title">Resumen</h3>
    <div class="columns">
      <div class="column">
        <hours-summary-counter :time="dayHours" type="Día" :accumulated-minutes="acummulatedMinutes" />
      </div>
      <div class="column">
        <hours-summary-counter :time="weekHours" type="Semana" :accumulated-minutes="acummulatedMinutes" />
      </div>
      <div class="column">
        <hours-summary-counter :time="monthHours" type="Mes" :accumulated-minutes="acummulatedMinutes" />
      </div>
    </div>
  </div>
</template>

<script>
import HoursSummaryCounter from "@/components/WorkedHours/HoursSummaryCounter";

export default {
  components: {
    HoursSummaryCounter
  },
  props: {
    dayHours: {
      type: Number,
      default: 0
    },
    weekHours: {
      type: Number,
      default: 0
    },
    monthHours: {
      type: Number,
      default: 0
    },
    acummulatedMinutes: {
      type: Number,
      default: 0
    }
  }
};
</script>
<style lang="scss" scoped>
.summary {
  color: white;
  width: 100%;
  text-align: center;
  background: $color-blue;
  padding: 2.5rem 0;
}
.title {
  margin-top: 0;
}
.columns {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>
