import axios from "axios";

const CATERING_ENDPOINT = process.env.VUE_APP_ETCONSULTING_HOST + "/";

export default {
  getUserCatering(beginDate, endDate) {
    return axios.get(CATERING_ENDPOINT + "catering?beginDate=" + beginDate + "&endDate=" + endDate);
  },
  getMonthDays(year, month) {
    return axios.get(CATERING_ENDPOINT + "catering/monthDays?year=" + year + "&month=" + month);
  },
  getCateringInfoById(userId, beginDate, endDate) {
    return axios.get(CATERING_ENDPOINT + "catering?userId=" + userId + "&beginDate=" + beginDate + "&endDate=" + endDate);
  },
  getCateringSummary(userId, beginDate, endDate) {
    return axios.get(CATERING_ENDPOINT + "catering/summary?userId=" + userId + "&beginDate=" + beginDate + "&endDate=" + endDate);
  },
  getMenu() {
    return axios.get(CATERING_ENDPOINT + "catering/menu", { responseType: "arraybuffer" });
  },
  getDailySummary(date) {
    return axios.get(CATERING_ENDPOINT + "catering/summary/daily?date=" + date, { responseType: "blob" });
  },
  getMonthlySummary(date) {
    return axios.get(CATERING_ENDPOINT + "catering/summary/monthly?date=" + date, { responseType: "blob" });
  },
  post(userId, date, menuTypeId, number, isLastMinuteSpecial) {
    let params = {
      userId: userId,
      date: date,
      menuTypeId: menuTypeId,
      number: number,
      isLastMinuteSpecial: isLastMinuteSpecial
    };
    return axios.post(CATERING_ENDPOINT + "catering", params);
  },
  edit(userId, date, menuTypeId, number, isLastMinuteSpecial) {
    let params = {
      userId: userId,
      date: date,
      menuTypeId: menuTypeId,
      number: number,
      isLastMinuteSpecial: isLastMinuteSpecial
    };
    return axios.put(CATERING_ENDPOINT + "catering", params);
  },
  delete(userId, date) {
    return axios.delete(CATERING_ENDPOINT + "catering/" + userId + "/" + date);
  },
  getMenuList(date) {
    return axios.get(CATERING_ENDPOINT + "catering/menu/list/" + date);
  },
  postMenuList(menu) {
    let params = {
      date: menu.date,
      firstCourseNormal: menu.firstCourseNormal,
      secondCourseNormal: menu.secondCourseNormal,
      dessertNormal: menu.dessertNormal,
      firstCourseSpecial: menu.firstCourseSpecial,
      secondCourseSpecial: menu.secondCourseSpecial,
      dessertSpecial: menu.dessertSpecial
    };
    return axios.post(CATERING_ENDPOINT + "catering/menu/list", params);
  },
  editMenuList(menu) {
    let params = {
      date: menu.date,
      firstCourseNormal: menu.firstCourseNormal,
      secondCourseNormal: menu.secondCourseNormal,
      dessertNormal: menu.dessertNormal,
      firstCourseSpecial: menu.firstCourseSpecial,
      secondCourseSpecial: menu.secondCourseSpecial,
      dessertSpecial: menu.dessertSpecial
    };
    return axios.put(CATERING_ENDPOINT + "catering/menu/list", params);
  },
  deleteCatering(companyId, date) {
    return axios.delete(CATERING_ENDPOINT + "catering/all?company_id=" + companyId + "&date=" + date);
  }
};
