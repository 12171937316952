import holidays from "@/logic/holidays";
import tokens from "@/logic/tokens";

export const storeHolidays = {
  state: {
    pendingApprovalHolidays: 0
  },
  mutations: {
    setPendingApprovalHolidays(state, newData) {
      state.pendingApprovalHolidays = newData;
    }
  },
  actions: {
    fetchPendingApprovalHolidays(context) {
      const decodedToken = tokens.getDecodedToken();
      if (decodedToken != null) {
        holidays
          .getPendingApprovalHolidays(decodedToken.userId)
          .then(response => {
            context.commit("setPendingApprovalHolidays", response.data);
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  },
  getters: {
    getPendingApprovalHolidays(state) {
      return state.pendingApprovalHolidays;
    }
  }
};
