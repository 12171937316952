import axios from "axios";
import tokens from "@/logic/tokens";
import router from "../router";
import store from "../store";

export default function setup() {
  axios.interceptors.request.use(function (config) {
    const token = tokens.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response) {
        // TODO: Llevar esta lógica a ets-components
        if (error.response.status === 401) {
          store.dispatch("logout");
          return router.replace({ name: "login" });
        }
        if (error.response.status !== 400) {
          router.replace({ name: "error", params: { error: error } });
        }
      } else {
        router.replace({ name: "error", params: { error: error } });
      }
      return Promise.reject(error);
    }
  );
}
