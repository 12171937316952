<template>
  <div>
    <div class="sub-header-container">
      <div class="sub-header">
        <div class="sub-header-buttons">
          <ets-button
            v-if="this.hasPermissionAdmin"
            :disabled="this.countPendingApproval === 0"
            text="Aprobar vacaciones"
            mode="main"
            @click="approveHolidays"
          />
          <ets-alert v-if="manageUserId && managedUserName" type="warning"
            >Estás modificando las vacaciones de {{ managedUserName }}</ets-alert
          >
          <div class="statistics">
            <div class="statistics-row-cell">
              <span>Sin consumir</span>
              <span>{{ this.notSpent }}</span>
            </div>
            <div class="statistics-row-cell">
              <span>Consumidos</span>
              <span>{{ this.spent }}</span>
            </div>
            <div class="statistics-row-cell">
              <span>Previstos</span>
              <span>{{ this.future }}</span>
            </div>
            <div class="statistics-row-cell">
              <span>Libres</span>
              <span>{{ this.free }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar">
      <ets-calendar-annual
        v-if="dates"
        :dates="dates"
        :end-date="endDate"
        @prev="refreshDates"
        @today="todayDates"
        @next="refreshDates"
        :clickable-from="firstClickableDate"
        @date-clicked="dateClickHandler"
        :legend="legend"
        :prev-text="prevText"
        :today-text="todayText"
        :next-text="nextText"
        :current-text="currentText"
      />
    </div>
    <ets-popup ref="editDatePopup" :title="selectedDate | etsdate" v-if="showEditDatePopup" @accept="editDate" @close="closeEditDate">
      <div class="popup-container">
        <div class="popup-col">
          <label class="popup-label">Tipo de vacaciones</label>
          <select v-model="newHolidayTypeId" class="popup-select">
            <!-- <option value="1" v-if="showOption(1)">Completo laborable</option>
            <option value="2" v-if="showOption(2)">Mañana laborable</option>
            <option value="3" v-if="showOption(3)">Tarde laborable</option>
            <option value="4" v-if="showOption(4)">Completo festivo</option>
            <option value="7" v-if="showOption(7)">Asuntos personales</option> -->
            <option v-for="(option, index) in holidayOptions" :value="option.value" :key="index">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
      <template slot="footer">
        <ets-button text="Anular día" mode="error" @click="deleteDate" />
      </template>
    </ets-popup>
    <ets-popup
      v-if="this.showForbiddenHoliday"
      title="Prohibido"
      @close="showForbiddenHoliday = false"
      @accept="showForbiddenHoliday = false"
    >
      <div>No se puede trabajar en este festivo.</div>
    </ets-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import holidays from "@/logic/holidays";
import users from "@/logic/users";
import tokens from "@/logic/tokens";

export default {
  data: () => ({
    beginDate: dayjs().startOf("year").toDate(),
    today: new Date(),
    endDate: dayjs().endOf("year").toDate(),
    dateToAdd: null,
    showEditDatePopup: false,
    selectedDate: null,
    historicalHolidays: [],
    futureHolidays: [],
    nationalHolidays: [],
    holidaySummary: {},
    notSpent: 0,
    spent: 0,
    free: 0,
    future: 0,
    hasPermissionAdmin: false,
    firstClickableDate: new Date(),
    showForbiddenHoliday: false,
    managedUserName: "",
    holidayOptions: []
  }),
  async mounted() {
    this.checkUser();
    this.fetchManagedUser();
    this.getHolidays();
    this.getHolidaySummary();
    this.getFutureHolidays();
    this.getNationalHolidays();
    this.getHolidayOption();
  },
  computed: {
    ...mapGetters(["getUserLogged"]),
    dates() {
      let a = this.historicalHolidays
        .map(x => ({
          date: new Date(x.date),
          cssClass: this.getCssClass(x.holidayTypeId, true, x.approved, x.date, x.year)
        }))
        .concat(
          this.futureHolidays.map(x => ({
            date: new Date(x.date),
            cssClass: this.getCssClass(x.holidayTypeId, false, x.approved, x.date, x.year)
          }))
        )
        .concat(
          this.nationalHolidays.map(x => ({
            date: new Date(x.date),
            cssClass: "national_holiday"
          }))
        );
      return a;
    },
    legend() {
      return [
        { color: "#99ccee", name: "Día disfrutado del año actual." },
        { color: "#99dd88", name: "Mañana disfrutada." },
        { color: "#5b934c", name: "Tarde disfrutada." },
        { color: "#c44e58", name: "Festivo trabajado." },
        { color: "#ddd97e", name: "Asuntos personales." },
        { border: "#99ccee", name: "Día completo laborable aprobado." },
        { border: "#99dd88", name: "Mañana laborable aprobada." },
        { border: "#5b934c", name: "Tarde laborable aprobada." },
        { border: "#c44e58", name: "Completo festivo aprobado." },
        { border: "#ddd97e", name: "Asuntos personales." },
        { border: "#b57ee0", name: "Día pagado." },
        { border: "#8b1de5", name: "Medio día pagado." },
        { color: "#e8e8e8", name: "Día disfrutado el año anterior." },
        { color: "#8c8c8c", name: "Mañana disfrutada del año anterior." },
        { color: "#8c8c8c", name: "Tarde disfrutada del año anterior." }
      ];
    },
    endDateHistorical() {
      return this.endDate.getFullYear() === new Date().getFullYear() ? new Date() : this.endDate;
    },
    beginDateFuture() {
      return this.endDate.getFullYear() > new Date().getFullYear() ? this.beginDate : this.endDateHistorical;
    },
    currentText() {
      return dayjs(this.endDate).year().toString();
    },
    prevText() {
      return dayjs(this.endDate).subtract(1, "year").year().toString();
    },
    nextText() {
      return dayjs(this.endDate).add(1, "year").year().toString();
    },
    todayText() {
      return "Año actual";
    },
    userId() {
      return tokens.getDecodedToken().userId;
    },
    manageUserId() {
      return this.$route.query.manageUserId;
    },
    countPendingApproval() {
      return this.futureHolidays.filter(holiday => !holiday.approved).length;
    }
  },
  methods: {
    async dateClickHandler(date) {
      this.getHolidayOption();
      let found = null;
      if (date < this.today) {
        found = this.historicalHolidays.find(d => new Date(d.date).getTime() === new Date(date).getTime());
      } else {
        found = this.futureHolidays.find(d => new Date(d.date).getTime() === new Date(date).getTime());
      }
      let isNationalHoliday = await holidays.checkIsNationalHoliday(dayjs(date).format("YYYYMMDD"));
      if (isNationalHoliday.data) {
        this.holidayOptions = this.holidayOptions.filter(option => option.value === "4");
        console.log("Es festivo");
      } else {
        this.holidayOptions = this.holidayOptions.filter(option => option.value !== "4");
        console.log("No es festivo");
      }
      let isForbiddenHoliday = await holidays.checkIsForbiddenHoliday2(dayjs(date).format("YYYYMMDD"));
      if (found) {
        this.showEditDatePopup = true;
        this.newHolidayTypeId = found.holidayTypeId;
        this.selectedDate = date;
      } else {
        if (isForbiddenHoliday.data) {
          this.showForbiddenHoliday = true;
        } else if (isNationalHoliday.data) {
          this.addDate(dayjs(date).format("YYYYMMDD"), dayjs(date).year(), 4);
        } else {
          this.addDate(dayjs(date).format("YYYYMMDD"), dayjs(date).year(), 1);
        }
      }
    },
    todayDates() {
      this.beginDate = dayjs().startOf("year").toDate();
      this.today = new Date();
      this.endDate = dayjs().endOf("year").toDate();

      this.getHolidays();
      this.getFutureHolidays();
    },
    refreshDates(date) {
      this.endDate = date;
      this.beginDate = new Date(dayjs(date).year(), 0, 1);
      if (dayjs(date).year() <= dayjs().year()) {
        this.getHolidays();
        this.getHolidaySummary();
      }
      if (dayjs(date).year() >= dayjs().year()) {
        this.getFutureHolidays();
        this.getHolidaySummary();
      }
      this.getNationalHolidays();
    },
    editDate() {
      this.updateHolidayInfo(dayjs(this.selectedDate).format("YYYYMMDD"), this.newHolidayTypeId, dayjs(this.selectedDate).year());
      this.showEditDatePopup = false;
    },
    deleteDate() {
      this.deleteHolidayDate(this.selectedDate);
      this.showEditDatePopup = false;
    },
    effectiveUser() {
      if (this.manageUserId != null) {
        return this.manageUserId;
      } else {
        return this.userId;
      }
    },
    async editFutureDates(date, holidayTypeId, year) {
      let user = this.effectiveUser();
      await holidays.edit(user, date, holidayTypeId, year);
    },
    async addFutureDates(date, holidayTypeId, year) {
      let user = this.effectiveUser();
      await holidays.post(user, date, holidayTypeId, year);
    },
    async deleteDates(date) {
      let user = this.effectiveUser();
      await holidays.delete(user, dayjs(date).format("YYYYMMDD"));
    },
    updateHolidayInfo(date, holidayTypeId, year) {
      try {
        this.editFutureDates(date, holidayTypeId, year).then(() => {
          this.getFutureHolidays();
          this.getHolidays();
          this.getHolidaySummary();
        });
      } catch (error) {
        this.$router.replace({ name: "error", params: { error: error } });
      }
    },
    deleteHolidayDate(date) {
      try {
        this.deleteDates(date).then(() => {
          this.getFutureHolidays();
          this.getHolidays();
          this.getHolidaySummary();
        });
      } catch (error) {
        this.$router.replace({ name: "error", params: { error: error } });
      }
    },
    addDate(date, year, holidayTypeId) {
      try {
        this.addFutureDates(date, holidayTypeId, year).then(() => {
          this.getFutureHolidays();
          this.getHolidays();
          this.getHolidaySummary();
        });
      } catch (error) {
        this.$router.replace({ name: "error", params: { error: error } });
      }
    },
    closeEditDate() {
      this.showEditDatePopup = false;
    },
    async getHolidays() {
      const start = dayjs(this.beginDate).format("YYYYMMDD");
      const end = dayjs(this.endDateHistorical).format("YYYYMMDD");
      try {
        let user = this.effectiveUser();
        let response = await holidays.getUserHolidaysById(user, start, end);
        this.historicalHolidays = response.data;
      } catch (error) {
        this.$router.replace({ name: "error", params: { error } });
      }
    },
    async getFutureHolidays() {
      const start = dayjs(this.beginDateFuture).format("YYYYMMDD");
      const end = dayjs(this.endDate).format("YYYYMMDD");
      try {
        let user = this.effectiveUser();
        let response = await holidays.getUserHolidaysById(user, start, end);
        this.futureHolidays = response.data;
      } catch (error) {
        this.$router.replace({ name: "error", params: { error } });
      }
    },
    async getNationalHolidays() {
      try {
        let year = dayjs(this.endDate).year().toString();
        let response = await holidays.getNationalHolidays(year);
        this.nationalHolidays = response.data;
      } catch (error) {
        this.$router.replace({ name: "error", params: { error } });
      }
    },
    async getHolidaySummary() {
      const start = dayjs(this.beginDate).format("YYYYMMDD");
      const end = dayjs(this.endDate).format("YYYYMMDD");
      try {
        let user = this.effectiveUser();
        let response = await holidays.getHolidaySummaryById(user, start, end);
        this.notSpent = response.data["notSpent"];
        this.spent = response.data["spent"];
        this.future = response.data["future"];
        this.free = response.data["free"];
      } catch (error) {
        this.$router.replace({ name: "error", params: { error } });
      }
    },
    async approveHolidays() {
      try {
        let user = this.effectiveUser();
        await holidays.approveHolidays(user);
        this.getFutureHolidays();
        this.getHolidaySummary();
      } catch (error) {
        this.$router.replace({ name: "error", params: { error } });
      }
    },
    getCssClass(holidayTypeId, historical, approved, date, year) {
      if (historical) {
        // Caso especial: vacaciones del año anterior
        if (dayjs(date).year() != year) {
          switch (holidayTypeId) {
            case 1:
              return "historical_complete_holiday_previous_year";
            case 2:
              return "historical_morning_holiday_previous_year";
            case 3:
              return "historical_afternoon_holiday_previous_year";
          }
        } else {
          if (approved) {
            switch (holidayTypeId) {
              case 1:
                return "historical_complete_holiday";
              case 2:
                return "historical_morning_holiday";
              case 3:
                return "historical_afternoon_holiday";
              case 4:
                return "historical_non_working_day";
              case 5:
                return "historical_paid_half";
              case 6:
                return "historical_paid_full";
              case 7:
                return "historical_personal_business";
              default:
                return "";
            }
          } else {
            // Aunque sea del pasado, si no está aprobada se muestra con línea discontinua,
            // como las del futuro
            switch (holidayTypeId) {
              case 1:
                return "future_complete_holiday";
              case 2:
                return "future_morning_holiday";
              case 3:
                return "future_afternoon_holiday";
              case 4:
                return "future_non_working_day";
              case 7:
                return "future_personal_business";
              default:
                return "";
            }
          }
        }
      } else if (approved) {
        switch (holidayTypeId) {
          case 1:
            return "future_complete_holiday_approved";
          case 2:
            return "future_morning_holiday_approved";
          case 3:
            return "future_afternoon_holiday_approved";
          case 4:
            return "future_non_working_day_approved";
          case 7:
            return "future_personal_business";
          default:
            return "";
        }
      } else {
        switch (holidayTypeId) {
          case 1:
            return "future_complete_holiday";
          case 2:
            return "future_morning_holiday";
          case 3:
            return "future_afternoon_holiday";
          case 4:
            return "future_non_working_day";
          case 7:
            return "future_personal_business";
          default:
            return "";
        }
      }
    },
    getHolidayOption() {
      this.holidayOptions = [
        { value: "1", label: "Completo laborable" },
        { value: "2", label: "Mañana laborable" },
        { value: "3", label: "Tarde laborable" },
        { value: "4", label: "Completo festivo" },
        { value: "7", label: "Asuntos personales" }
      ];
    },
    async checkUser() {
      let logged_user = await users.getUser(this.userId);
      this.hasPermissionAdmin = logged_user.data.isAdminHolidays || logged_user.data.isSAHolidays;
      if (this.hasPermissionAdmin) {
        this.firstClickableDate = null;
      }
      if (this.manageUserId != null) {
        if (!logged_user.data.isAdminHolidays && !logged_user.data.isSAHolidays) {
          this.$router.push({ name: "userinfo" });
        }
      }
    },
    async fetchManagedUser() {
      if (this.manageUserId != null) {
        const response = await users.getUser(this.manageUserId);
        this.managedUserName = response.data.name + " " + response.data.surnames;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar ::v-deep {
  .historical_complete_holiday {
    box-shadow: 0 0 0 2px #99ccee;
    background-color: #99ccee !important;
    color: white;
  }
  .historical_morning_holiday {
    box-shadow: 0 0 0 2px #99dd88;
    background-color: #99dd88 !important;
    color: white;
  }
  .historical_afternoon_holiday {
    box-shadow: 0 0 0 2px #5b934c;
    background-color: #5b934c !important;
    color: white;
  }
  .historical_complete_holiday_previous_year {
    box-shadow: 0 0 0 2px #e8e8e8;
    background-color: #e8e8e8 !important;
    color: white;
  }
  .historical_morning_holiday_previous_year {
    box-shadow: 0 0 0 2px #8c8c8c;
    background-color: #8c8c8c !important;
    color: white;
  }
  .historical_afternoon_holiday_previous_year {
    box-shadow: 0 0 0 2px #8c8c8c;
    background-color: #8c8c8c !important;
    color: white;
  }
  .historical_non_working_day {
    box-shadow: 0 0 0 2px #c44e58;
    background-color: #c44e58 !important;
    color: white;
  }
  .historical_paid_half {
    box-shadow: 0 0 0 2px #8b1de5;
    background-color: #8b1de5 !important;
    color: white;
  }
  .historical_paid_full {
    box-shadow: 0 0 0 2px #b57ee0;
    background-color: #b57ee0 !important;
    color: white;
  }
  .historical_personal_business {
    box-shadow: 0 0 0 2px #ddd97e;
    background-color: #ddd97e !important;
    color: white;
  }
  .future_complete_holiday_approved {
    box-shadow: 0 0 0 2px #99ccee;
    color: black;
  }
  .future_morning_holiday_approved {
    box-shadow: 0 0 0 2px #99dd88;
    color: black;
  }
  .future_afternoon_holiday_approved {
    box-shadow: 0 0 0 2px #5b934c;
    color: black;
  }
  .future_non_working_day_approved {
    box-shadow: 0 0 0 2px #c44e58;
    color: black;
  }
  .future_personal_business_approved {
    box-shadow: 0 0 0 2px #ddd97e;
    color: black;
  }
  .national_holiday {
    box-shadow: 0 0 0 2px #eb1404;
    background-color: #eb1404 !important;
    color: white;
  }
  .future_complete_holiday {
    border-color: #99ccee;
    border-style: dashed;
    color: black;
  }
  .future_morning_holiday {
    border-color: #99dd88;
    border-style: dashed;
    color: black;
  }
  .future_afternoon_holiday {
    border-color: #5b934c;
    border-style: dashed;
    color: black;
  }
  .future_non_working_day {
    border-color: #c44e58;
    border-style: dashed;
    color: black;
  }
  .future_personal_business {
    border-color: #ddd97e;
    border-style: dashed;
    color: black;
  }
  .calendar-wrapper {
    justify-content: space-around;
  }
  .today-button {
    @include mobile-only {
      position: relative;
      margin-top: 2rem;
    }
  }
}
.popup-container {
  display: inline-flex;
}
.popup-col {
  padding: 0.5rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  min-width: 280px;
}
.popup-label {
  margin-top: 18px;
  margin-bottom: 18px;
  display: block;
}
.popup-select {
  width: 100%;
}
.calendar {
  margin: 0px auto;
  @include desktop {
    width: 75%;
  }
}
.sub-header-container {
  margin-top: 2rem;
}
.sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
}
.sub-header-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.statistics {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: $color-blue;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: medium;
}
.statistics-row-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 20px;
}
</style>
