import axios from "axios";

const CHECKS_ENDPOINT = process.env.VUE_APP_ETCONSULTING_HOST + "/";

export default {
  getChecksList(startDate) {
    return axios.get(CHECKS_ENDPOINT + "checks?date=" + startDate);
  },
  post(type, time, comment) {
    let params = { checkType: type, checkTime: time };
    if (comment) {
      params.comment = comment;
    }
    return axios.post(CHECKS_ENDPOINT + "checks", params);
  },
  delete(id) {
    return axios.delete(CHECKS_ENDPOINT + "checks/" + id);
  },
  getChecksRange(start, end) {
    return axios.get(CHECKS_ENDPOINT + `checks?beginDate=${start}&endDate=${end}`);
  },
  getWorkedHours(date) {
    return axios.get(CHECKS_ENDPOINT + "checks/workedHours?date=" + date);
  }
};
