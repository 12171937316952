import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

export default {
  getToken() {
    return Cookies.get("tokenETConsulting");
  },
  getDecodedToken() {
    const token = this.getToken();
    if (token) return jwtDecode(token);
  }
};
