var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('header',{staticClass:"header"},[_vm._m(0),_vm._m(1),_c('router-link',{attrs:{"to":"/login"}},[_c('ets-button',{attrs:{"mode":"success","text":"Inicia sesión"}})],1)],1),_c('main',[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('section',{attrs:{"id":"who"}},[_vm._m(6),_c('div',{staticClass:"members"},_vm._l((_vm.members),function(member,i){return _c('landing-member',{key:i,attrs:{"imageUrl":member.imageUrl,"name":member.name,"role":member.role}})}),1)]),_vm._m(7)]),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":"logo.svg","alt":"Logo ETS Serenitas"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"nav"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#why"}},[_vm._v("Por qué")])]),_c('li',[_c('a',{attrs:{"href":"#what"}},[_vm._v("Qué")])]),_c('li',[_c('a',{attrs:{"href":"#how"}},[_vm._v("Cómo")])]),_c('li',[_c('a',{attrs:{"href":"#who"}},[_vm._v("Quién")])]),_c('li',[_c('a',{attrs:{"href":"#for-whom"}},[_vm._v("Para quién")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"main"}},[_c('img',{staticClass:"secondary-logo",attrs:{"src":"logo.svg","alt":"logo secundario"}}),_c('h1',[_c('strong',[_vm._v("\"")]),_c('small',[_vm._v(" El aprecio por las")]),_c('br'),_c('strong',[_vm._v("Cosas bien hechas")]),_c('strong',[_vm._v("\"")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"why"}},[_c('div',{staticClass:"section-container section-grid"},[_c('h1',{staticClass:"section-title"},[_c('small',[_vm._v("El acento, en la")]),_c('br'),_c('strong',[_vm._v("Confianza")])]),_c('div',{staticClass:"section-content"},[_c('p',[_vm._v(" ETS Serenitas lleva más de 25 años prestando servicios de oficina de familia a la familia Bolívar (propietaria del grupo empresarial Expert Timing Systems) y a todo su ecosistema de empresas. ")]),_c('p',[_c('strong',[_vm._v("Ofrecemos nuestros servicios también a aquellos clientes que compartan nuestro aprecio por las cosas bien hechas")]),_vm._v(", poniendo nuestra experiencia al servicio de sus compañías o patrimonios familiares. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"what"}},[_c('div',{staticClass:"section-container"},[_c('h1',{staticClass:"section-title"},[_c('small',[_vm._v("El acento, en la")]),_c('br'),_c('strong',[_vm._v("Excelencia")])]),_c('div',{staticClass:"section-content"},[_c('ul',{staticClass:"what-areas"},[_c('li',[_c('h3',[_vm._v("Administración y RRHH")]),_c('p',[_vm._v(" Incluye todos los servicios relacionados con facturación, alquileres, contratos, oficinas, gestión de empleados, trámites personales. ")])]),_c('li',[_c('h3',[_vm._v("Contabilidad")]),_c('p',[_vm._v("Dado el carácter sensible de la información a tratar, se realiza la contabilidad personal y empresarial internamente.")])]),_c('li',[_c('h3',[_vm._v("Finanzas")]),_c('p',[_vm._v(" Gestión de tesorería para empresas y patrimonios familiares que faciliten a nuestros clientes la toma de decisiones de inversión y que aseguren una gestión correcta del riesgo de liquidez. ")])]),_c('li',[_c('h3',[_vm._v("Fiscalidad")]),_c('p',[_vm._v(" Colaboramos con equipos de prestigio internacional que nos permiten ofrecer a nuestros clientes un asesoramiento fiscal de primer nivel. ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"how"}},[_c('div',{staticClass:"section-container section-grid"},[_c('h1',{staticClass:"section-title"},[_c('small',[_vm._v("El acento, en los")]),_c('br'),_c('strong',[_vm._v("Detalles")])]),_c('div',{staticClass:"section-content"},[_c('p',[_c('strong',[_vm._v("Nuestra misión es dar soluciones")]),_vm._v(" que permitan a nuestros clientes centrarse en los aspectos más estratégicos de sus compañías resolviendo de forma solvente, continuada y profesional todo aquello relacionado con áreas más operativas en la administración diaria de sus empresas o patrimonios. ")]),_c('p',[_vm._v(" Por el carácter eminentemente familiar que nos caracteriza, ETS Serenitas no limita su trabajo a una lista tasada de servicios, sino que ofrece una "),_c('strong',[_vm._v("administración integral del patrimonio personal y empresarial de nuestros clientes")]),_vm._v(". ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"section-title"},[_c('small',[_vm._v("El acento, en las")]),_c('br'),_c('strong',[_vm._v("Personas")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"for-whom"}},[_c('div',{staticClass:"section-container section-grid"},[_c('h1',{staticClass:"section-title"},[_c('small',[_vm._v("El acento, en la")]),_c('br'),_c('strong',[_vm._v("Cercanía")])]),_c('div',{staticClass:"section-content"},[_c('p',[_vm._v(" Para mantener un alto estándar de calidad y conexión con nuestros clientes, "),_c('strong',[_vm._v("valoramos mucho las recomendaciones de quienes ya nos conocen")]),_vm._v(". A través de estas recomendaciones, no solo aseguramos mantener la calidad de nuestro servicio, sino también fortalecemos los lazos de confianza y familiaridad dentro de nuestra comunidad empresarial. ")]),_c('p',[_vm._v(" Sin embargo, estamos abiertos a conocer nuevas personas interesadas en nuestros servicios y estamos dispuestos a explorar cómo podemos satisfacer sus necesidades de la mejor manera posible. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('ul',[_c('li',[_c('a',{attrs:{"href":"tel:+34913572471","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/phone.svg"),"alt":"phone"}}),_c('span',[_vm._v("+34 91 357 24 71")])])]),_c('li',[_c('a',{attrs:{"href":"mailto:info@etsfactory.com"}},[_c('img',{attrs:{"src":require("@/assets/icons/mail.svg"),"alt":"phone"}}),_c('span',[_vm._v("info@etsfactory.com")])])]),_c('li',[_c('a',{attrs:{"href":"https://www.linkedin.com/company/etsfactory","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/linkedin.svg"),"alt":"phone"}}),_c('span',[_vm._v("LinkedIn")])])]),_c('li',[_c('a',{attrs:{"href":"https://twitter.com/EtsFactory","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/twitter.svg"),"alt":"phone"}}),_c('span',[_vm._v("Twitter")])])])])])
}]

export { render, staticRenderFns }