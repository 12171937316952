<template>
  <div class="weekly-check" :style="computedStyle" :class="{ current: current, finished: !finished }">
    <div>
      <div class="content" v-if="width > 10 || (width > 6 && current)">
        <div class="title">check in</div>
        <div>{{ formatDate(from) }}</div>
      </div>
    </div>
    <div v-if="!current && finished && width > 6" class="right">
      <div class="title">check out</div>
      <div>{{ formatDate(to) }}</div>
    </div>
    <div v-else-if="!finished" class="right">
      <div class="title">not finished</div>
      <div>Please check out</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      default: ""
    },
    to: {
      type: String,
      default: ""
    },
    minHour: {
      type: Number,
      default: 0
    },
    maxHour: {
      type: Number,
      default: 0
    },
    current: {
      type: Boolean,
      default: false
    },
    finished: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    computedStyle: {}
  }),
  computed: {
    fromHour() {
      let date = new Date(this.from);
      let { hours, minutes } = this.setMaxAndMinLimits(date.getHours(), date.getMinutes());
      return this.timeToNumber(hours, minutes);
    },
    toHour() {
      let date = new Date(this.to);
      let { hours, minutes } = this.setMaxAndMinLimits(date.getHours(), date.getMinutes());
      return this.timeToNumber(hours, minutes);
    },
    min() {
      return this.timeToNumber(this.minHour - 1, 30);
    },
    max() {
      return this.timeToNumber(this.maxHour, 30);
    },
    width() {
      return ((this.toHour - this.fromHour) * 100) / (this.max - this.min);
    },
    padding() {
      return this.width > 4 ? "0 1.5rem" : "0 1px";
    },
    positionLeft() {
      return this.normalize(this.fromHour, this.min, this.max, 0, 100);
    }
  },
  methods: {
    normalize(val, minVal, maxVal, newMin, newMax) {
      return newMin + ((val - minVal) * (newMax - newMin)) / (maxVal - minVal);
    },
    timeToNumber(hour, minutes) {
      return hour * 60 + minutes;
    },
    formatDate(dateToFormat) {
      let date = new Date(dateToFormat);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      return hours + ":" + minutes + "h";
    },
    calculateStyles() {
      this.computedStyle = { width: this.width + "%", left: this.positionLeft + "%", padding: this.padding };
    },
    setMaxAndMinLimits(hours, minutes) {
      if ((hours === this.maxHour && minutes > 30) || hours > this.maxHour) {
        hours = this.maxHour;
        minutes = 30;
      }
      if ((hours === this.minHour - 1 && minutes < 30) || hours < this.minHour - 1) {
        hours = this.minHour - 1;
        minutes = 30;
      }
      return { hours, minutes };
    }
  },
  mounted() {
    setTimeout(() => {
      this.calculateStyles();
    });
  },
  watch: {
    from() {
      this.calculateStyles();
    },
    to() {
      this.calculateStyles();
    }
  }
};
</script>
<style lang="scss" scoped>
.weekly-check {
  height: 100%;
  background: $color-primary;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: white;
  font-size: 1rem;
  width: 0;
  transition: all 0.5s;
}
.title {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-bottom: 0.3rem;
}
.right {
  text-align: right;
}
.finished {
  background: $color-red;
}
.current {
  background: $color-secondary-light;
}
</style>
