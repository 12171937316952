<template>
  <div class="weekly-checks">
    <div class="days">
      <div class="spacer-header"></div>
      <div class="day p-s" v-for="(day, i) in days" :key="'day' + i">{{ day }}</div>
    </div>
    <div class="right">
      <weekly-checks-header :steps="steps" />
      <div class="weekly-bars">
        <div class="selected-day" :style="positionCurrentDay"></div>
        <div class="line" v-for="i in steps + 1" :key="'line' + i" :style="linePosition(i)"></div>
        <div class="row" v-for="(row, i) in formattedChecks" :key="'row' + i">
          <div class="line-today" v-if="displayToday(i) && showToday" :style="linePositionToday"></div>
          <check-bar
            v-for="(check, j) in formattedChecks[i]"
            :key="'row' + j"
            :from="check.startDate"
            :to="check.endDate"
            :minHour="minHour"
            :maxHour="maxHour"
            :current="check.current"
            :finished="check.finished"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WeeklyChecksHeader from "@/components/WorkedHours/WeeklyChecksHeader";
import CheckBar from "@/components/WorkedHours/CheckBar";
import { EtsDateParser } from "ets-components/src/utils";

const days = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes"];
export default {
  components: {
    WeeklyChecksHeader,
    CheckBar
  },
  props: {
    date: {
      type: String,
      default: ""
    },
    checks: {
      type: Array,
      default: () => []
    },
    steps: {
      type: Number,
      default: 16
    },
    minHour: {
      type: Number,
      default: 7
    },
    maxHour: {
      type: Number,
      default: 23
    },
    todayDate: {
      type: Date,
      default: new Date()
    },
    currentDate: {
      type: Date,
      default: new Date()
    }
  },
  data: () => ({
    days: days
  }),
  computed: {
    formattedChecks() {
      let checks = [[], [], [], [], []];
      let i = 0;
      let now = new Date();
      while (i <= this.checks.length - 1) {
        let checkIn = this.checks[i];
        let checkinDate = new Date(checkIn.checkTime);
        let check = null;
        if (i + 2 > this.checks.length) {
          if (this.sameDay(now, checkinDate)) {
            check = this.createCurrentCheck(checkIn);
          } else {
            check = this.createNotFinishedCheck(checkIn);
          }
        } else {
          let checkOut = this.checks[i + 1];
          check = this.createCheck(checkIn, checkOut);
        }
        let day = new Date(checkIn.checkTime).getDay() - 1; // 0 is Sunday
        checks[day].push(check);
        i += 2;
      }
      return checks;
    },
    linePositionToday() {
      let min = (this.minHour - 1) * 60 + 30;
      let max = this.maxHour * 60 + 30;

      let today = this.todayDate.getHours() * 60 + this.todayDate.getMinutes();

      return { left: 0 + ((today - min) * (100 - 0)) / (max - min) + "%" };
    },
    positionCurrentDay() {
      return { top: (this.currentDate.getDay() - 1) * 60 + "px" };
    },
    showToday() {
      return this.weekNumber(this.currentDate) === this.weekNumber(new Date());
    }
  },
  methods: {
    linePosition(i) {
      let ammount = 100 / (this.steps + 1);
      return { left: ammount * i - ammount / 2 + "%" };
    },
    displayToday(i) {
      return i === new Date().getDay() - 1;
    },
    createCurrentCheck(checkIn) {
      return {
        startDate: checkIn.checkTime,
        endDate: EtsDateParser(this.todayDate, "YYYY-MM-DD HH:mm:ss"), // Current time
        current: true
      };
    },
    createNotFinishedCheck(checkIn) {
      let auxDate = new Date(checkIn.checkTime).setHours(this.maxHour); // Same day but the last hour
      let endDate = EtsDateParser(new Date(auxDate).setMinutes(30), "YYYY-MM-DD HH:mm:ss");
      return {
        startDate: checkIn.checkTime,
        endDate: endDate,
        finished: false // Day is not finished
      };
    },
    createCheck(checkIn, checkOut) {
      return {
        startDate: checkIn.checkTime,
        endDate: checkOut.checkTime,
        current: false,
        finished: true
      };
    },
    sameDay(d1, d2) {
      return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    },
    weekNumber(d) {
      const target = new Date(d.valueOf());
      const dayNr = (d.getDay() + 6) % 7;

      target.setDate(target.getDate() - dayNr + 3);

      const jan4 = new Date(target.getFullYear(), 0, 4);
      const dayDiff = (target - jan4) / 86400000;

      const weekNr = 1 + Math.ceil(dayDiff / 7);

      return weekNr;
    }
  }
};
</script>

<style lang="scss" scoped>
.weekly-checks {
  display: flex;
  width: 100%;
}
.day {
  display: flex;
  padding: 0 1.5rem;
  height: 60px;
  align-items: center;
  color: $color-grey3;
  text-transform: uppercase;
}
.spacer-header {
  background: #eeeeee;
  height: 42px;
}
.right {
  width: 100%;
}
.weekly-bars {
  position: relative;
  width: 100%;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.line {
  height: 100%;
  width: 1px;
  background: #dddddd;
  position: absolute;
}
.line-today {
  height: 100%;
  width: 1px;
  position: absolute;
  border-right: 2px solid $color-yellow;
  z-index: 2;
  transition: left 0.3s;
}
.selected-day {
  height: 60px;
  width: 100%;
  position: absolute;
  background: $color-grey6;
  transition: top 0.5s;
}
.row {
  height: 60px;
  position: relative;
  width: 100%;
  margin: 0px auto;
  border-bottom: 1px solid white;
}
.check {
  height: 100%;
}
</style>
