import axios from "axios";

const COMPANIES_ENDPOINT = process.env.VUE_APP_ETCONSULTING_HOST + "/";

export default {
  getAllCompanies() {
    return axios.get(COMPANIES_ENDPOINT + "companies");
  },
  getCompany(companyId) {
    return axios.get(COMPANIES_ENDPOINT + "companies/" + companyId);
  },
  getCompanyUsers(companyId, service) {
    return axios.get(COMPANIES_ENDPOINT + "companies/" + companyId + "/users?service=" + service);
  },
  post(name) {
    let params = {
      name: name
    };
    return axios.post(COMPANIES_ENDPOINT + "companies", params);
  },
  edit(companyId, name) {
    let params = {
      name: name
    };
    return axios.put(COMPANIES_ENDPOINT + "companies/" + companyId, params);
  },
  delete(companyId) {
    return axios.delete(COMPANIES_ENDPOINT + "companies/" + companyId);
  }
};
