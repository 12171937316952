<template>
  <div>
    <div class="sub-header-container">
      <div class="sub-header">
        <ets-button
          :disabled="!hasPermissionProcessPending"
          text="Procesar vacaciones pendientes del año anterior"
          mode="main"
          @click="processPendingHolidays"
        />
      </div>
    </div>

    <!-- selector año -->
    <div class="calendar">
      <div class="ets-calendar-container">
        <div class="selector ets-calendar">
          <div class="buttons-center">
            <ets-button :text="prevText" @click="prevDates" icon="chevron-left" />
            <span :text="currentText" class="current-year-span">
              {{ this.currentText }}
            </span>
            <ets-button :text="nextText" @click="nextDates" reversed icon="chevron-right" />
          </div>
        </div>
      </div>
    </div>

    <!-- tabla ets -->
    <ets-table :columns="columns" :data="this.usersInformation" :fill="true">
      <template slot="row" slot-scope="item">
        <td>
          <a :href="urlHolidays(item.row.userId)">{{ item.row.fullName }}</a>
        </td>
        <td class="left">{{ item.row.notSpent }}</td>
        <td class="left">{{ item.row.spent }}</td>
        <td class="left">{{ item.row.future }}</td>
        <td class="left">{{ item.row.free }}</td>
        <td class="left">{{ item.row.extra }}</td>
        <td>
          <ets-button-group>
            <ets-button text="Editar" @click="editExtraDays(item.row.userId, item.row.extra)" />
          </ets-button-group>
        </td>
        <td class="left">
          <span class="nav-item-badge">{{ item.row.pending }}</span>
        </td>
        <td>
          <ets-button-group>
            <ets-button text="Aprobar vacaciones" @click="approveHolidays(item.row.userId)" />
          </ets-button-group>
        </td>
      </template>
    </ets-table>
    <ets-popup
      ref="editExtraDaysPopup"
      title="Días extra"
      v-if="showEditExtraDaysPopup"
      @accept="updateExtraDays"
      @close="closeEditExtraDays"
    >
      <div class="popup-container">
        <div class="popup-col">
          <label class="popup-label">Número de días</label>
          <input v-model="newExtraDays" type="number" />
        </div>
      </div>
    </ets-popup>
  </div>
</template>

<script>
import companies from "@/logic/companies";
import dayjs from "dayjs";
import holidays from "@/logic/holidays";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import tokens from "@/logic/tokens";
import users from "@/logic/users";

export default {
  data: () => ({
    usersInformation: [],
    loggedUser: null,
    beginDate: dayjs().startOf("year").toDate(),
    endDate: dayjs().endOf("year").toDate(),
    // For the year selector
    steps: 12,
    showEditExtraDaysPopup: false,
    selectedUser: 0,
    newExtraDays: 0,
    hasPermissionAdmin: false,
    hasPermissionProcessPending: false
  }),
  async mounted() {
    await this.checkUser();
    await this.getData();
    await this.sortByPending();
  },
  computed: {
    ...mapGetters(["getUserLogged"]),
    columns() {
      return [
        {
          text: "Apellidos, Nombre",
          key: "fullName",
          sorteable: false
        },
        {
          text: "Sin consumir",
          key: "notSpent",
          sorteable: false
        },
        {
          text: "Consumidos",
          key: "spent",
          sorteable: false
        },
        {
          text: "Previstos",
          key: "future",
          sorteable: false
        },
        {
          text: "Libre",
          key: "free",
          sorteable: false
        },
        {
          text: "Extra",
          key: "extra",
          sorteable: false
        },
        {
          text: " ",
          sorteable: false,
          class: "icons"
        },
        {
          text: "Pendientes",
          key: "pending",
          sorteable: false
        },
        {
          text: " ",
          sorteable: false,
          class: "icons"
        }
      ];
    },
    currentText() {
      return dayjs(this.endDate).year().toString();
    },
    prevText() {
      return dayjs(this.endDate).subtract(1, "year").year().toString();
    },
    nextText() {
      return dayjs(this.endDate).add(1, "year").year().toString();
    }
  },
  methods: {
    ...mapActions(["fetchPendingApprovalHolidays"]),
    async getLoggedUser() {
      let token = tokens.getDecodedToken();
      if (token) {
        let response = await users.getUser(token.userId);
        return response.data;
      }
      return null;
    },
    normalize(name) {
      return name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },
    urlHolidays(userId) {
      return `/holidays?manageUserId=${userId}`;
    },
    async prevDates() {
      this.beginDate = dayjs(this.beginDate).subtract(this.steps, "months").toDate();
      this.endDate = dayjs(this.endDate).subtract(this.steps, "months").endOf("month").toDate();
      await this.getData();
    },
    async nextDates() {
      this.beginDate = dayjs(this.beginDate).add(this.steps, "months").toDate();
      this.endDate = dayjs(this.endDate).add(this.steps, "months").endOf("month").toDate();
      await this.getData();
    },
    editExtraDays(userId, currentExtraDays) {
      this.showEditExtraDaysPopup = true;
      this.newExtraDays = currentExtraDays;
      this.selectedUser = this.usersInformation.find(user => user.userId === userId);
    },
    closeEditExtraDays() {
      this.showEditExtraDaysPopup = false;
    },
    async updateExtraDays() {
      await holidays.deleteExtraHolidaysById(this.selectedUser.userId, dayjs(this.beginDate).year().toString());
      await holidays.insertExtraHolidaysById(this.selectedUser.userId, this.newExtraDays, dayjs(this.beginDate).year().toString());
      this.showEditExtraDaysPopup = false;
      await this.getData();
    },
    async approveHolidays(userId) {
      try {
        await holidays.approveHolidays(userId);
        await this.getData();
        await this.sortByPending();
      } catch (error) {
        this.$router.replace({ name: "error", params: { error } });
      }
    },
    async getData() {
      await this.getLoggedUser().then(user => {
        this.loggedUser = user;
      });
      let response = await companies.getCompanyUsers(this.loggedUser.companyId, "holidays");
      let usersInfo = response.data;

      const users_partial = [];
      await Promise.all(
        usersInfo.map(async user => {
          const userHolidays = await holidays.getHolidaySummaryById(
            user.userId,
            dayjs(this.beginDate).format("YYYYMMDD"),
            dayjs(this.endDate).format("YYYYMMDD")
          );
          const newUser = Object.assign(user, userHolidays.data);
          newUser.fullName = newUser.surnames + ", " + newUser.name;
          users_partial.push(newUser);
        })
      );
      this.usersInformation = users_partial;
      this.usersInformation.sort((a, b) => (a.pending > b.pending ? 1 : -1));
      this.fetchPendingApprovalHolidays();
    },
    async sortByPending() {
      this.usersInformation.sort((a, b) => (a.f > b.f ? 1 : -1));
    },
    async checkUser() {
      this.loggedUser = await this.getLoggedUser();
      this.hasPermissionAdmin = this.loggedUser.isAdminHolidays || this.loggedUser.isSAHolidays;
      // De momento solo Merce va a tenir permiso para el botón de procesar vacacione pendientes del año anterior
      this.hasPermissionProcessPending = this.hasPermissionAdmin && this.loggedUser.companyId == 2;
      if (!this.hasPermissionAdmin) {
        this.$router.push({ name: "userinfo" });
      }
    },
    async processPendingHolidays() {
      const previousYear = new Date().getFullYear() - 1;
      await holidays.processPendingHolidays(this.loggedUser.userId, previousYear);
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-header-container {
  margin-top: 2rem;
}
.sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
}
.users-container {
  width: 65%;
  margin: 5rem auto;
}
.users {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.users li {
  display: flex;
  justify-content: space-between;
}
.calendar ::v-deep {
  .calendar-wrapper {
    justify-content: space-around;
  }
}
.ets-calendar-container {
  width: 100%;
}
.current-year-span {
  color: #757575;
  margin: 0 10px;
}
.next-button {
  ::v-deep .button-content {
    display: flex;
    flex-direction: row-reverse;
  }
}
.selector {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  align-items: center;
}
.buttons-center {
  display: flex;
  align-items: center;
}
</style>
